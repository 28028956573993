pr-single-widget .pr-widget-body {
  position: relative;
}
pr-single-widget .TABLE,
pr-single-widget .TEXT {
  height: auto !important;
}
pr-single-widget .TABLE .pr-widget-wrapper .pr-widget-body,
pr-single-widget .TEXT .pr-widget-wrapper .pr-widget-body {
  position: relative;
}
