.pr-page-margins {
  border: 1px solid transparent;
}
.show-grid .pr-page-margins {
  border-color: rgba(0, 0, 0, 0.03);
  -webkit-background-size: 48px 48px;
  -moz-background-size: 48px 48px;
  -o-background-size: 48px 48px;
  background-size: 48px 48px;
  background-image: repeating-linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03) 1px, transparent 1px, transparent 8px), repeating-linear-gradient(-90deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03) 1px, transparent 1px, transparent 8px), repeating-linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03) 1px, transparent 1px, transparent 48px), repeating-linear-gradient(-90deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03) 1px, transparent 1px, transparent 48px);
}
/**
 * Public Report
 */
.show-margins .public-report .pr-page-margins:after,
.show-margins .public-report .pr-page-margins:before,
.show-margins .public-report pr-page-header-footer:after,
.show-margins .public-report pr-page-header-footer:before {
  border: none;
}
.show-margins .public-report .pr-page-margins {
  background-image: none;
  border: none;
}
.show-margins .pr-page-margins:after,
.show-margins .pr-page-margins:before {
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  border: 1px dashed #D7D7D7;
}
.show-margins .pr-page-margins:before {
  top: -1000px;
  bottom: -1000px;
}
.show-margins .pr-page-margins:after {
  right: -1000px;
  left: -1000px;
}
pr-page-header-footer {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
}
pr-page-header-footer[type='footer'] {
  top: auto;
  bottom: 0;
}
pr-page-header-footer table {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-spacing: 0;
}
pr-page-header-footer table td {
  padding: 0;
}
pr-page-header-footer table .title {
  overflow: hidden;
}
pr-page-header-footer table .logo {
  max-height: 100%;
}
.show-margins pr-page-header-footer:after {
  content: '';
  display: block;
  position: absolute;
  right: -1000px;
  bottom: 0px;
  left: -1000px;
  border-bottom: 1px dashed #D7D7D7;
}
.show-margins pr-page-header-footer[type='footer']:after {
  top: 0px;
  bottom: auto;
}
