.pr-pages {
  z-index: 0;
}
.pr-page {
  position: relative;
  margin: 32px auto;
  width: 816px;
  height: 1056px;
  background: #FFF;
  box-shadow: 0 5px 25px -10px #000;
}
/*
 * When moving or resizing a widget, we want to ignore z-index
 */
.grid-item-cursor .pr-page {
  z-index: auto;
}
@media print {
  .no-widget-insert {
    display: none;
  }
}
.public-report .no-widget-insert {
  display: none;
}
.no-widget-insert {
  position: relative;
  margin-left: 50%;
  margin-left: calc(50% - 37px);
  text-align: center;
  padding-right: 12px;
}
.no-widget-insert .btn-sm {
  border-radius: 14px;
}
.letter .pr-page {
  width: 816px;
  height: 1056px;
}
.letter.horizontal .pr-page {
  width: 1056px;
  height: 816px;
}
.legal .pr-page {
  width: 816px;
  height: 1344px;
}
.legal.horizontal .pr-page {
  width: 1344px;
  height: 816px;
}
.a4 .pr-page {
  width: 792px;
  height: 1120px;
}
.a4.horizontal .pr-page {
  width: 1120px;
  height: 792px;
}
.b4 .pr-page {
  width: 944px;
  height: 1328px;
}
.b4.horizontal .pr-page {
  width: 1328px;
  height: 944px;
}
.fullscreen .pr-page {
  width: 960px;
  height: 720px;
}
.fullscreen.vertical .pr-page {
  width: 720px;
  height: 960px;
}
.widescreen1 .pr-page {
  width: 1280px;
  height: 720px;
}
.widescreen1.vertical .pr-page {
  width: 720px;
  height: 1280px;
}
.widescreen2 .pr-page {
  width: 960px;
  height: 600px;
}
.widescreen2.vertical .pr-page {
  width: 600px;
  height: 960px;
}
