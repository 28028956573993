.pr-undo-btn .icon,
.pr-redo-btn .icon {
  position: relative;
}
.pr-undo-btn .icon {
  left: 4px;
  transform: rotate(-45deg);
}
.pr-redo-btn .icon {
  left: -4px;
  transform: rotate(45deg) scaleX(-1);
}
