.form-group > label {
  display: block;
  color: #737373;
  font-weight: 300;
}
.form-group > label[disabled] {
  color: #D7D7D7;
}
.btn.btn-tall {
  height: auto;
  white-space: normal;
  line-height: 17px;
}
