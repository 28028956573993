div[class*='resize-handle'] {
  position: absolute;
  display: none;
  width: 16px;
  height: 16px;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
div[class*='resize-handle']:after {
  content: "";
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  border: 1px solid #1876F5;
  background: #FFF;
}
.resize-handle-nw {
  top: -8px;
  left: -8px;
  cursor: nw-resize;
}
.resize-handle-n {
  top: -8px;
  left: 50%;
  margin-left: -8px;
  cursor: n-resize;
}
.resize-handle-ne {
  top: -8px;
  right: -8px;
  cursor: ne-resize;
}
.resize-handle-e {
  top: 50%;
  right: -8px;
  margin-top: -8px;
  cursor: e-resize;
}
.resize-handle-se {
  right: -8px;
  bottom: -8px;
  cursor: se-resize;
}
.resize-handle-s {
  bottom: -8px;
  left: 50%;
  margin-left: -8px;
  cursor: s-resize;
}
.resize-handle-sw {
  bottom: -8px;
  left: -8px;
  cursor: sw-resize;
}
.resize-handle-w {
  left: -8px;
  top: 50%;
  margin-top: -8px;
  cursor: w-resize;
}
.item-selector {
  position: fixed;
  background: rgba(24, 118, 245, 0.25);
  opacity: 0.5;
  border: 1px solid #1876F5;
}
.item-resizer {
  position: absolute;
  pointer-events: none;
}
.item-resizer:after {
  content: '';
  position: absolute;
  top: -1px;
  right: 0;
  bottom: 0;
  left: -1px;
  border: 1px solid #1876F5;
}
.item-resizer div[class|='resize-handle'] {
  display: block;
  pointer-events: all;
}
.item-resizer.x-only div[class^='resize-handle-n'],
.item-resizer.x-only div[class^='resize-handle-s'] {
  display: none;
}
.item-resizer.y-only div[data-resize-handle-dir$='e'],
.item-resizer.y-only div[data-resize-handle-dir$='w'] {
  display: none;
}
