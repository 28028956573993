.pr-ee-header {
  font-size: 12pt;
  padding: 15px;
  border-bottom: 1px solid #D7D7D7;
}
.pr-ee-header .entry {
  vertical-align: top;
}
.pr-ee-header .icon {
  vertical-align: top;
}
