.public-report-modal .public-report-url {
  cursor: pointer;
  background: inherit;
  width: 100%;
  max-width: 267px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.public-report-modal .public-report-url a {
  cursor: inherit;
}
.public-report-modal .public-report-url .form-control {
  background: #eee;
}
.public-report-modal .header {
  font-weight: bold;
}
.public-report-modal .copy-public-report-url {
  cursor: pointer;
  vertical-align: middle;
}
.public-report-modal .public-report-info {
  padding: 8px;
}
.public-report-modal .public-report-info > *,
.public-report-modal .public-report-info .q-switch {
  vertical-align: middle;
  display: inline-block;
}
.public-report-modal .public-report-info .disabled {
  color: gray;
  pointer-events: none;
  cursor: default;
}
.public-report-modal .textbar {
  width: 250px;
}
.public-report-modal .offset {
  margin-left: 35px;
}
.public-report-modal .eye {
  cursor: pointer;
}
.public-report-modal .passcode-input-box.ng-invalid:not(.ng-pristine) {
  border-color: #d13a34;
}
