.pr-widget-toolbar-options {
  /* Around 950px we don't have enough space to show the txt format options
	 *	these CSS changes will buy us some space until 860
	 */
  /* this gets us from 860 to 802 */
}
.pr-widget-toolbar-options .btn {
  background-color: transparent;
}
.pr-widget-toolbar-options .header-menu .btn {
  width: 85px;
}
.pr-widget-toolbar-options .font-menu .btn {
  width: 95px;
}
.pr-widget-toolbar-options .size-menu .btn {
  width: 50px;
}
.pr-widget-toolbar-options .piped-text-menu {
  float: right;
  margin-left: 4px;
  margin-right: -4px;
}
.pr-widget-toolbar-options .txt-format-divider {
  display: inline-block;
  border-left: 1px solid #C6C6C6;
  margin: -14px 0px;
  height: 42px;
}
@media (max-width: 950px) {
  .pr-widget-toolbar-options .header-menu .btn {
    width: 60px;
  }
  .pr-widget-toolbar-options .font-menu .btn {
    width: 60px;
  }
  .pr-widget-toolbar-options .txt-format-divider {
    display: none;
  }
}
@media (max-width: 865px) {
  .pr-widget-toolbar-options .btn-sm:not(.btn-select) {
    padding-left: 5px;
    padding-right: 5px;
  }
}
