.rater-single-display-logic {
  margin-top: -13px;
}
.rater-single-display-logic .form-input-group {
  display: table-cell;
}
.rater-single-display-logic .form-input-group .display-logic-menu-selector {
  display: inline-block;
  top: 13px;
}
.rater-single-display-logic .form-input-group .display-logic-menu-selector.rater-options {
  min-width: 175px;
  max-width: 200px;
}
.rater-single-display-logic .form-input-group .display-logic-menu-selector.operator-options {
  width: 165px;
}
.rater-single-display-logic .form-input-group .response-count-threshold {
  display: inline-block;
}
.rater-single-display-logic .form-input-group .text-info {
  display: inline-block;
}
.rater-single-display-logic .form-input-group .text-info.has-text {
  margin-left: 3px;
  margin-right: 3px;
}
.rater-single-display-logic .form-input-group .text-info.responses-text {
  margin-left: 5px;
  margin-right: 5px;
}
