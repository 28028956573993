.pr-tb-add-widget-buttons {
  margin: 0 -2px;
}
.pr-tb-add-widget-buttons .btn {
  position: relative;
  margin: 0 2px;
}
.pr-tb-add-widget-buttons .other-widget-btn {
  padding-right: 8px;
  margin-left: -1px;
}
