.pr-widget-error {
  /* Set to match the format of misconfigured widgets */
  height: 100%;
  color: #C6C6C6;
  background: #F9F9F9;
}
.pr-widget-error .alert-horizontal {
  display: table;
  width: 100%;
  height: 100%;
}
.pr-widget-error .alert-vertical {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
