.pr-dynamic-translations .form-group {
  margin-bottom: 15px;
}
.pr-dynamic-translations .dynamic-translations-info {
  padding: 4px 0px;
  display: block;
  color: #737373;
  font-weight: 300;
}
.pr-dynamic-translations .fixed-width {
  width: 24% !important;
}
.pr-dynamic-translations .status {
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-top: 8px;
  padding: 15px;
  text-align: center;
  max-width: 500px;
}
.pr-dynamic-translations .pending {
  background-color: #CCE4F2;
  border-color: #085CAF;
  color: #085CAF;
}
.pr-dynamic-translations .failed {
  background-color: #F5D7D6;
  border-color: #BB342E;
  color: #BB342E;
}
.pr-dynamic-translations .succeeded {
  background-color: #CCE9DC;
  border-color: #008448;
  color: #008448;
}
.pr-dynamic-translations .error-message-text {
  vertical-align: bottom;
  line-height: 2.3;
}
