.pr-no-reports,
.pr-error-message {
  text-align: center;
  margin: 40px 0;
  color: #737373;
}
.pr-no-reports .main-icon,
.pr-error-message .main-icon,
.pr-no-reports .msg,
.pr-error-message .msg,
.pr-no-reports .btn,
.pr-error-message .btn {
  margin: 20px 0;
}
.pr-no-reports .main-icon,
.pr-error-message .main-icon {
  font-size: 90px;
}
.pr-no-reports .msg,
.pr-error-message .msg {
  font-size: 20px;
}
