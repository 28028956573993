.pr-toolbar {
  position: absolute;
  top: 42px;
  right: 0;
  left: 0;
  overflow: hidden;
  height: 42px;
  padding: 0 8px;
  z-index: 2;
  background: #F9F9F9;
  border-bottom: 1px solid #D7D7D7;
  opacity: 1 !important;
}
.fixed .pr-toolbar {
  -webkit-box-shadow: 0 0 6px -3px #000;
  -moz-box-shadow: 0 0 6px -3px #000;
  -o-box-shadow: 0 0 6px -3px #000;
  box-shadow: 0 0 6px -3px #000;
}
.pr-tb-center {
  display: inline;
}
.pr-tb-center .pr-tb-btn-group {
  display: inline;
  float: left;
}
.pr-tb-left {
  display: inline;
}
.pr-tb-left .pr-tb-btn-group {
  float: left;
}
.pr-tb-right {
  display: inline;
}
.pr-tb-right .pr-tb-btn-group {
  float: right;
}
.pr-tb-btn-group {
  height: 42px;
  padding: 8px;
}
.pr-tb-left .pr-tb-btn-group {
  border-right: 1px solid #C6C6C6;
}
.pr-tb-right .pr-tb-btn-group {
  border-left: 1px solid #C6C6C6;
}
.pr-tb-search-input {
  position: relative;
}
.pr-tb-search-input i {
  position: absolute;
  top: 1px;
  left: 1px;
  color: #C6C6C6;
}
.pr-tb-search-input input {
  padding-left: 24px;
}
