.pr-inline-quill-header {
  height: auto !important;
}
pr-widget.selected .pr-inline-quill-header:hover {
  background: #E8E8E8;
}
.text-edit-mode pr-widget.selected .pr-inline-quill-header:hover {
  background: inherit;
}
.text-edit-mode .pr-inline-quill-header {
  cursor: text !important;
}
.text-edit-mode pr-widget:not(.selected),
.text-edit-mode pr-widget.selected .view-body {
  opacity: 0.4;
}
.text-edit-mode .item-resizer [class^='resize-handle'] {
  display: none !important;
}
.text-edit-mode pr-widget.active .pr-widget-wrapper,
.text-edit-mode pr-widget.selected .pr-widget-wrapper {
  outline: none;
}
