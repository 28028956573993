.pr-toc {
  position: absolute;
  top: 84px;
  left: 0;
  bottom: 0;
  width: 0;
  padding: 12px 0 80px;
  overflow: auto;
  overflow-x: hidden;
  background: #F9F9F9;
  border-right: 1px solid #D7D7D7;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
  transition: width 0.3s;
}
.show-toc .pr-toc {
  width: 0;
}
.pr-toc-container {
  position: relative;
  float: right;
  margin: 0;
  padding: 12px 0 12px 40px;
  opacity: 1;
  width: 0;
  max-height: 240px;
  -webkit-transition: opacity 0.4s, max-height 0.4s, margin 0.4s;
  -moz-transition: opacity 0.4s, max-height 0.4s, margin 0.4s;
  -ms-transition: opacity 0.4s, max-height 0.4s, margin 0.4s;
  -o-transition: opacity 0.4s, max-height 0.4s, margin 0.4s;
  transition: opacity 0.4s, max-height 0.4s, margin 0.4s;
}
.pr-toc-container.selected .pr-toc-page {
  border: 1px solid #0B6ED0;
  box-shadow: 0 1px 6px rgba(0, 122, 192, 0.4);
}
.pr-toc-container.removed {
  max-height: 0;
  opacity: 0;
  margin: 0 0 -24px;
}
.pr-toc-container.show-page-break {
  border-top: 1px dashed #D7D7D7;
}
.pr-toc-container .moving {
  display: none;
}
.pr-toc-control {
  position: absolute;
  display: table;
  top: 14px;
  left: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  background: transparent;
  -webkit-transition: background 0.2s;
  -moz-transition: background 0.2s;
  -ms-transition: background 0.2s;
  -o-transition: background 0.2s;
  transition: background 0.2s;
}
.pr-toc-control .pr-toc-number {
  opacity: 1;
}
.pr-toc-control .pr-toc-number {
  -webkit-transition: opacity 0.2s, transform 0.2s;
  -moz-transition: opacity 0.2s, transform 0.2s;
  -ms-transition: opacity 0.2s, transform 0.2s;
  -o-transition: opacity 0.2s, transform 0.2s;
  transition: opacity 0.2s, transform 0.2s;
}
.pr-toc-number {
  display: table-cell;
  text-align: center;
  font-size: 12px;
  font-color: #222222;
  vertical-align: middle;
  opacity: 1;
}
.pr-toc-page {
  position: relative;
  overflow: hidden;
  background: #FFFFFF;
  box-shadow: 0 1px 5px -2px #000;
}
.pr-toc-page.active,
.pr-toc-page:hover {
  border: 1px solid #0B6ED0;
  box-shadow: 0 1px 6px rgba(0, 122, 192, 0.4);
}
.pr-toc-page .pr-page {
  margin: 0;
  z-index: 0;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
}
.pr-toc-page .pr-page-margins {
  display: none;
}
.pr-toc-page:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.pr-toc-page {
  width: 148px;
}
.letter .pr-toc-page {
  height: 191.52941176px;
}
.letter .pr-toc-page .pr-page {
  -webkit-transform: scale(0.18137255);
  -moz-transform: scale(0.18137255);
  -ms-transform: scale(0.18137255);
  -o-transform: scale(0.18137255);
}
.letter.horizontal .pr-toc-page {
  height: 114.36363636px;
}
.letter.horizontal .pr-toc-page .pr-page {
  -webkit-transform: scale(0.14015152);
  -moz-transform: scale(0.14015152);
  -ms-transform: scale(0.14015152);
  -o-transform: scale(0.14015152);
}
.legal .pr-toc-page {
  height: 243.76470588px;
}
.legal .pr-toc-page .pr-page {
  -webkit-transform: scale(0.18137255);
  -moz-transform: scale(0.18137255);
  -ms-transform: scale(0.18137255);
  -o-transform: scale(0.18137255);
}
.legal.horizontal .pr-toc-page {
  height: 89.85714286px;
}
.legal.horizontal .pr-toc-page .pr-page {
  -webkit-transform: scale(0.11011905);
  -moz-transform: scale(0.11011905);
  -ms-transform: scale(0.11011905);
  -o-transform: scale(0.11011905);
}
.a4 .pr-toc-page {
  height: 209.29292929px;
}
.a4 .pr-toc-page .pr-page {
  -webkit-transform: scale(0.18686869);
  -moz-transform: scale(0.18686869);
  -ms-transform: scale(0.18686869);
  -o-transform: scale(0.18686869);
}
.a4.horizontal .pr-toc-page {
  height: 104.65714286px;
}
.a4.horizontal .pr-toc-page .pr-page {
  -webkit-transform: scale(0.13214286);
  -moz-transform: scale(0.13214286);
  -ms-transform: scale(0.13214286);
  -o-transform: scale(0.13214286);
}
.b4 .pr-toc-page {
  height: 208.20338983px;
}
.b4 .pr-toc-page .pr-page {
  -webkit-transform: scale(0.15677966);
  -moz-transform: scale(0.15677966);
  -ms-transform: scale(0.15677966);
  -o-transform: scale(0.15677966);
}
.b4.horizontal .pr-toc-page {
  height: 105.20481928px;
}
.b4.horizontal .pr-toc-page .pr-page {
  -webkit-transform: scale(0.11144578);
  -moz-transform: scale(0.11144578);
  -ms-transform: scale(0.11144578);
  -o-transform: scale(0.11144578);
}
.fullscreen .pr-toc-page {
  height: 111px;
}
.fullscreen .pr-toc-page .pr-page {
  -webkit-transform: scale(0.15416667);
  -moz-transform: scale(0.15416667);
  -ms-transform: scale(0.15416667);
  -o-transform: scale(0.15416667);
}
.fullscreen.vertical .pr-toc-page {
  height: 197.33333333px;
}
.fullscreen.vertical .pr-toc-page .pr-page {
  -webkit-transform: scale(0.20555556);
  -moz-transform: scale(0.20555556);
  -ms-transform: scale(0.20555556);
  -o-transform: scale(0.20555556);
}
.widescreen1 .pr-toc-page {
  height: 83.25px;
}
.widescreen1 .pr-toc-page .pr-page {
  -webkit-transform: scale(0.115625);
  -moz-transform: scale(0.115625);
  -ms-transform: scale(0.115625);
  -o-transform: scale(0.115625);
}
.widescreen1.vertical .pr-toc-page {
  height: 263.11111111px;
}
.widescreen1.vertical .pr-toc-page .pr-page {
  -webkit-transform: scale(0.20555556);
  -moz-transform: scale(0.20555556);
  -ms-transform: scale(0.20555556);
  -o-transform: scale(0.20555556);
}
.widescreen2 .pr-toc-page {
  height: 92.5px;
}
.widescreen2 .pr-toc-page .pr-page {
  -webkit-transform: scale(0.15416667);
  -moz-transform: scale(0.15416667);
  -ms-transform: scale(0.15416667);
  -o-transform: scale(0.15416667);
}
.widescreen2.vertical .pr-toc-page {
  height: 236.8px;
}
.widescreen2.vertical .pr-toc-page .pr-page {
  -webkit-transform: scale(0.24666667);
  -moz-transform: scale(0.24666667);
  -ms-transform: scale(0.24666667);
  -o-transform: scale(0.24666667);
}
