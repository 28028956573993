.pr-page-layout .label-group > label {
  margin-right: 16px;
}
.pr-page-layout .margin-option {
  margin-bottom: 8px;
}
.pr-page-layout .margin-option .direction {
  min-width: 56px;
  display: inline-block;
}
.pr-page-layout .advanced-link {
  padding-left: 4px;
}
.pr-page-layout .qspinner.simple {
  padding-right: 19px;
}
.pr-page-layout .qspinner.simple .btn {
  width: 19px;
  height: 19px;
}
.pr-page-layout .qspinner.simple .btn:before {
  top: 6px;
  left: 5px;
}
.pr-page-layout .orientation .btn {
  width: 40px;
  height: 40px;
}
.pr-page-layout .orientation .btn .icon {
  line-height: 17px;
  height: auto;
}
.pr-page-layout .orientation .btn.landscape .icon {
  transform: rotate(90deg);
}
.pr-page-layout .options-group {
  margin: 16px 8px;
}
