.pr-filebar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 42px;
  padding: 0 8px;
  z-index: 2;
  background: #F9F9F9;
  border-bottom: 1px solid #D7D7D7;
}
.pr-filebar .pr-report-update {
  display: inline-block;
  color: #737373;
  font-size: 13px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  line-height: 42px;
}
.pr-filebar .pr-report-update .error {
  color: #FFFFFF;
  vertical-align: middle;
  background-color: #D13A34;
  border: solid;
  border-color: #D13A34;
}
.pr-filebar .pr-report-update .icon-caution {
  vertical-align: middle;
}
.pr-timezone-wrapper {
  display: inline-block;
}
.fixed .pr-filebar {
  position: fixed;
}
.pr-fb-btn {
  margin-top: 8px;
  border: none;
  font-size: 14px;
}
.file-bar-label {
  color: #737373;
  opacity: 0.95;
}
.title-label {
  opacity: 0.8;
}
.report-name-label {
  color: #222222;
}
.report-menu-wrapper {
  float: left;
  margin: 8px 0 8px 8px;
}
.report-menu {
  margin-top: 0px;
  max-width: 240px;
}
.file-menu {
  margin-left: 8px;
}
.pr-fb-btn:hover,
.pr-fb-btn.active {
  background: #F0F0F0;
}
.pr-fb-separator {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 42px;
  margin: 0 8px;
  border-right: 1px solid #D7D7D7;
}
.qmenu .menu-items .hotkeys {
  opacity: .65;
  font-weight: 300;
  float: right;
}
.qmenu .menu-items .hotkeys .icon {
  line-height: 0px;
  position: relative;
  top: 5px;
  right: -4px;
}
.qmenu .menu-items .hotkeys .icon-shiftkey {
  right: -13px;
}
.pr-fb-right {
  float: right;
  padding: 0px 8px;
}
.pr-bulk-field-dropdown {
  display: inline-block;
}
.pr-fb-timezone {
  display: inline-block;
  color: #737373;
  font-size: 13px;
}
.pr-fb-timezone .timezone-offset {
  font-weight: 300;
}
.pr-bulk-field-menu {
  display: inline-block;
  vertical-align: middle;
  height: 25px;
  width: 150px;
  padding: 0px 10px;
  margin: 0px 0px 0px 4px;
}
.pr-bulk-field-menu .caret {
  top: 5px !important;
}
.pr-bulk-field-callout {
  display: inline-block;
  color: #737373;
  font-size: 13px;
}
