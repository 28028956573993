.global-option {
  margin: 28px;
}
.global-option-body {
  padding: 0;
  width: 621px;
  height: 520px;
}
.global-options-sidebar {
  width: 220px;
  float: left;
  display: inline-block;
  margin-right: 30px;
}
.global-options-sidebar .global-options-text-option {
  display: inline-block;
}
.global-options-sidebar .global-options-text-option .global-options-font-menu .btn {
  width: 120px;
}
.global-options-sidebar .global-option-input {
  margin-top: 6px;
}
.global-options-sidebar .global-option-input .qspinner.simple {
  padding-right: 19px;
}
.global-options-sidebar .global-option-input .qspinner.simple .btn {
  width: 19px;
  height: 19px;
}
.global-options-sidebar .global-option-input .qspinner.simple .btn:before {
  top: 6px;
  left: 5px;
}
.global-options-sidebar .modify-palettes {
  display: block;
  margin-top: -10px;
}
.global-options-sidebar .global-options-totals-config {
  padding-left: 27px;
  margin-top: 0px;
}
.global-options-sidebar .global-options-totals-config label,
.global-options-sidebar span {
  color: #737373;
  font-weight: 300;
}
.global-options-sidebar .percentage-denominator-input {
  padding-left: 27px;
}
.global-options-sidebar .global-enable-totals-label {
  display: inline;
  padding-left: 15px;
}
.global-options-preview {
  display: inline-block;
}
.global-options-preview .global-option-preview-text {
  margin-bottom: 15px;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.global-options-preview .preview-widget-wrap,
.global-options-preview .preview-table-wrap {
  margin-bottom: 15px;
  border: 1px solid #0B6ED0;
  width: 300px;
}
.global-options-preview .preview-widget-body {
  outline: 1px dashed #0B6ED0;
  height: 150px;
}
.global-options-preview .preview-table-wrap {
  margin-bottom: 15px;
}
.global-options-preview .preview-table-wrap .preview-table-body {
  outline: 1px dashed #0B6ED0;
  min-height: 150px;
}
.global-options-preview pr-global-options-preview-scales .preview-widget-body {
  padding: 12px;
  height: 200px;
}
#global-options-preview-scales {
  height: 125px;
}
.global-globals-btn-group {
  float: left;
}
.global-globals-btn-group .global-globals-use-default {
  float: left;
}
.modify-rater-display-logic {
  display: block;
  margin-top: 5px;
}
