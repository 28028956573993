.display-logic-modal-body {
  min-width: 800px;
  min-height: 425px;
  padding: 0;
}
.display-logic-modal-body .sidebar-section {
  width: 25%;
  background-color: #F9F9F9;
  height: 100%;
  padding: 0px;
  position: absolute;
  left: 0;
  top: 0;
}
.display-logic-modal-body .saved-display-logics {
  overflow-y: scroll;
  position: relative;
  height: 77%;
}
.display-logic-modal-body .saved-display-logic {
  border: 1px solid #E8E8E8;
  border-radius: 0;
  border-top: none;
  background-color: transparent;
  color: black;
  height: 55px;
  width: 100%;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}
.display-logic-modal-body .error-state {
  color: #D13A34 !important;
}
.display-logic-modal-body .custom-metric-error-state {
  border-color: #D13A34 !important;
  box-shadow: none;
}
.display-logic-modal-body .selected {
  color: #FFFFFF;
  background-color: #0B6ED0;
}
.display-logic-modal-body .saved-display-logic-btn {
  border: none;
  border-radius: 0;
  background-color: transparent;
  color: inherit;
  font-size: 14px;
  height: 55px;
  padding-top: 20px;
  text-align: left;
  text-overflow: ellipsis;
  box-shadow: none;
  width: 80%;
  white-space: normal;
}
.display-logic-modal-body .display-logic-preview {
  width: 75%;
  margin-left: 25%;
  padding: 20px;
}
.display-logic-modal-body .display-logic-preview .show-only-where-text {
  margin: 12px 0 12px 5px;
  color: #A4A4A4;
}
.display-logic-modal-body .display-logic-preview .display-logic-source {
  margin-top: 20px;
}
.display-logic-modal-body .display-logic-source {
  margin-top: 20px;
}
@media (min-width: 786px) {
  .display-logic-modal-body {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .display-logic-modal-body {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .display-logic-modal-body {
    width: 1170px;
  }
}
@media (min-width: 1000px) {
  .saved-display-logic-btn {
    width: 82%;
  }
}
@media (min-width: 1200px) {
  .saved-display-logic-btn {
    width: 85%;
  }
}
.saved-display-logic-btn:hover {
  background-color: transparent;
  color: inherit;
}
.saved-display-logic-btn:active {
  box-shadow: none;
}
.saved-display-logic-btn-icons {
  width: 55px;
  border-radius: 0;
  border: none;
  position: absolute;
  right: 0;
  display: inline-block;
  top: 16px;
  color: #737373;
}
.create-btn-wrapper {
  background-color: #F9F9F9;
  border: 1px solid #E8E8E8;
  border-radius: 0;
  padding: 7px;
  position: absolute;
  width: 100%;
  bottom: 0px;
  border-bottom: none;
}
.create-btn-wrapper .btn-success {
  width: 100%;
}
.display-logic-name-form {
  max-width: 80%;
}
