/*
 * Animate both the widget and the resizer when widgets fit their final locations
 */
.animate pr-widget {
  opacity: 1;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -ms-transition: all 0.1s;
  -o-transition: all 0.1s;
  transition: all 0.1s;
}
.item-resizer {
  -webkit-transition: opacity 0.05s;
  -moz-transition: opacity 0.05s;
  -ms-transition: opacity 0.05s;
  -o-transition: opacity 0.05s;
  transition: opacity 0.05s;
}
.animate .item-resizer {
  opacity: 0;
}
.insert-border:after {
  content: '';
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  height: 4px;
  background-color: #0B6ED0;
}
.insert-border:after.border-after {
  top: auto;
  bottom: -5px;
}
widget-insert {
  position: absolute;
  display: block;
  border: 2px solid #0B6ED0;
  transition: top 0.2s, left 0.2s;
}
.widget-moving .item-resizer,
.widget-moving .top-buttons,
.widget-moving .bottom-buttons {
  visibility: hidden;
}
.widget-moving pr-widget.active:not(.moving) {
  opacity: 0;
}
.widget-moving pr-widget.moving {
  background-color: #FFF;
  -webkit-transform: scale(0.25);
  -moz-transform: scale(0.25);
  -ms-transform: scale(0.25);
  -o-transform: scale(0.25);
}
