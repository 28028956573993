.type-btn {
  margin: 0 5px;
}
.middle-aligned {
  vertical-align: middle;
  display: inline-block;
}
.display-logic-list li {
  margin-top: 1em;
}
.display-logic-list {
  list-style: none;
  padding-left: 40px;
  margin: 0;
  max-width: 90%;
}
.display-logic-list .icon.icon-minus-framed,
.display-logic-list .icon.icon-plus-framed {
  display: inline-block;
  margin-left: 2px;
  margin-top: 2px;
  color: #A4A4A4;
  cursor: pointer;
}
.display-logic-list .icon.icon-plus-framed {
  margin-left: -9px;
}
