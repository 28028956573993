@media print {
  /* Hide everything that isn't in the path to the report */
  html > *:not(body),
  body > *:not(ng-view):not(.hidden-renderer),
  ng-view > *:not(pr-main):not(pr-single-widget),
  pr-main > *:not(.main):not(pr-hide-entire-report),
  .main > *:not(pr-body),
  pr-body > *:not(.pr-body),
  .pr-body > *:not(pr-report) {
    display: none !important;
  }
  /* Hide other stuff too*/
  pr-page-break {
    display: none;
  }
  .conditional-display-indicator {
    display: none;
  }
  .show-margins .pr-page-margins:after,
  .show-margins .pr-page-margins:before,
  .show-margins pr-page-header-footer:after {
    display: none !important;
  }
  pr-body,
  .pr-body {
    padding: 0 !important;
  }
  /* Remove left and top padding from report */
  .pr-report {
    background: #FFF !important;
    float: left;
    padding: 0 !important;
  }
  /* Remove manual alignment of report page to middle of screen */
  .pr-page {
    float: left;
    clear: both;
    /* Removing this line because it truncates widgets during exports */
    /* @todo figure out why this truncation happens */
    /* @todo figure out what this line was meant to do */
    /* overflow:hidden; */
    page-break-inside: avoid;
    page-break-after: always;
    box-shadow: none !important;
    margin: 0 !important;
  }
  .pr-zoom-window,
  .pr-zoom-content {
    left: 0px !important;
    height: auto !important;
    transform: initial !important;
  }
  /* Show partial data pdf message on each page */
  .pr-partial-data-export-banner {
    display: inline !important;
  }
}
