pr-widget pr-insert-widget-button .btn {
  cursor: pointer !important;
}
pr-widget pr-insert-widget-button .insert-widget-button-wrapper {
  display: inline-block;
}
pr-widget .top-buttons pr-insert-widget-button {
  float: left;
}
pr-widget .top-buttons pr-insert-widget-button .btn {
  border-radius: 14px 0 0 14px;
}
pr-widget .bottom-buttons pr-insert-widget-button .btn {
  border-radius: 14px;
}
