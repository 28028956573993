.pr-authenticate-public-report {
  background: none repeat scroll 0 0 #eee;
  height: 100%;
  left: 0;
  padding-top: 0;
  position: absolute;
  right: 0;
  top: 0;
  text-align: center;
}
.passcode-container {
  margin: auto;
  margin-top: 20%;
  position: relative;
  width: 300px;
  background: white;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #cacaca;
  text-align: center;
}
.passcode-container .btn {
  position: relative;
}
.alert-danger {
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
  text-align: center;
}
.circles {
  height: 64px;
  position: relative;
  margin-bottom: 24px;
}
.xs-circle {
  width: 18px;
  height: 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: none;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -10px;
  margin-top: -10px;
  -webkit-transition: width 500ms cubic-bezier(0.61, 0, 0.145, 1), height 500ms cubic-bezier(0.61, 0, 0.145, 1);
  -o-transition: width 500ms cubic-bezier(0.61, 0, 0.145, 1), height 500ms cubic-bezier(0.61, 0, 0.145, 1);
  -moz-transition: width 500ms cubic-bezier(0.61, 0, 0.145, 1), height 500ms cubic-bezier(0.61, 0, 0.145, 1);
  transition: width 500ms cubic-bezier(0.61, 0, 0.145, 1), height 500ms cubic-bezier(0.61, 0, 0.145, 1);
}
.circle01 {
  background: #97D700;
  -webkit-transform: translate(-57.5%, -57.5%);
  -moz-transform: translate(-57.5%, -57.5%);
  -ms-transform: translate(-57.5%, -57.5%);
  -o-transform: translate(-57.5%, -57.5%);
  transform: translate(-57.5%, -57.5%);
}
.circle02 {
  background: #147BD1;
  -webkit-transform: translate(57.5%, -57.5%);
  -moz-transform: translate(57.5%, -57.5%);
  -ms-transform: translate(57.5%, -57.5%);
  -o-transform: translate(57.5%, -57.5%);
  transform: translate(57.5%, -57.5%);
}
.circle03 {
  background: #464E7E;
  -webkit-transform: translate(-57.5%, 57.5%);
  -moz-transform: translate(-57.5%, 57.5%);
  -ms-transform: translate(-57.5%, 57.5%);
  -o-transform: translate(-57.5%, 57.5%);
  transform: translate(-57.5%, 57.5%);
}
.circle04 {
  background: #2DCCD3;
  -webkit-transform: translate(57.5%, 57.5%);
  -moz-transform: translate(57.5%, 57.5%);
  -ms-transform: translate(57.5%, 57.5%);
  -o-transform: translate(57.5%, 57.5%);
  transform: translate(57.5%, 57.5%);
}
