.ql-editor,
.ql-editor[contenteditable]:focus {
  background: transparent;
  border: 0 solid transparent;
  margin: 0;
  padding: 0;
}
.ql-editor:empty:after {
  content: 'Click to edit text...';
}
.ql-editor:empty:focus:after {
  content: '';
}
/* Clear out defaults */
.ql-container {
  font-family: inherit;
  font-size: inherit;
  height: inherit;
  line-height: inherit;
  margin: inherit;
  padding: inherit;
  position: inherit;
  overflow-x: inherit;
  overflow-y: inherit;
  /********************************************************
	*  URGENT  *********************************************
	 ********************************************************
	*  Changes made to these styles will change the layout
	*  of customers reports
	 ********************************************************
	 ******************************************************** */
}
.ql-container p {
  margin: 0;
}
.ql-container ul,
.ql-container ol {
  margin: 8px 0;
  padding-left: 40px;
}
.ql-container h1,
.ql-container h2,
.ql-container h3,
.ql-container h4,
.ql-container h5,
.ql-container h6 {
  font-weight: normal;
}
.ql-container .ql-class-footnote {
  font-size: 8pt;
  color: #737373;
}
.ql-container .ql-class-footnote,
.ql-container .ql-class-footnote * {
  font-family: times, "Times Roman", "Times New Roman", TimesNewRoman, serif;
}
.ql-container .ql-font-times,
.ql-container .ql-font-times * {
  font-family: times, "Times Roman", "Times New Roman", TimesNewRoman, serif;
}
.ql-container .ql-font-arial,
.ql-container .ql-font-arial * {
  font-family: arial, helvetica, sans-serif;
}
.ql-container .ql-font-georgia,
.ql-container .ql-font-georgia * {
  font-family: georgia, times, serif;
}
.ql-container .ql-font-helvetica,
.ql-container .ql-font-helvetica * {
  font-family: helvetica, arial, sans-serif;
}
.ql-container .ql-font-times_new_roman,
.ql-container .ql-font-times_new_roman * {
  font-family: "Times New Roman", TimesNewRoman, times, "Times Roman", serif;
}
.ql-container .ql-font-verdana,
.ql-container .ql-font-verdana * {
  font-family: verdana, Geneva, arial, helvetica, sans-serif;
}
.ql-container .ql-font-courier,
.ql-container .ql-font-courier * {
  font-family: courier, monospace;
}
.ql-container .ql-font-courier_new,
.ql-container .ql-font-courier_new * {
  font-family: "courier new", courier, monospace;
}
.ql-container .piped-text-highlight {
  background-color: #D1E1ED;
  border-radius: 5px;
  user-select: all;
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  padding: 0px 1px;
}
.ql-container .piped-text-highlight.failed-fetch {
  background-color: #D13A34;
}
.ql-container.disable-highlight .piped-text-highlight {
  background-color: transparent;
}
.ql-container.disable-highlight .piped-text-highlight.failed-fetch {
  background-color: #D13A34;
}
.ql-container .pt-tooltip {
  display: none;
  pointer-events: auto;
  user-select: none;
}
.ql-container .pt-tooltip .pt-dropdown {
  position: relative;
  top: 5px;
  margin-left: 5px;
  margin-right: -10px;
  margin-top: -10px;
}
.hyperlink-highlight {
  border-radius: 5px;
  user-select: all;
  -webkit-user-select: all;
  -moz-user-select: all;
  -ms-user-select: all;
  padding: 0px 1px;
  pointer-events: all;
}
/* Styles for piped text tooltip */
.tooltip-inner {
  max-width: 400px;
}
