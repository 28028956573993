.pr-partial-data-export-banner {
  display: none;
  margin-top: -3px;
}
.pr-partial-data-export-banner.left-banner {
  position: absolute;
}
.pr-partial-data-export-banner.right-banner {
  float: right;
}
.pr-partial-data-export-banner .partial-data-warning {
  width: 100%;
  text-align: center;
  font-size: 18px;
  opacity: 0.3;
  z-index: 99;
  position: relative;
  margin-top: -5px;
  color: gray;
  padding: 2px;
  /* @todo investigate why we can't use font-weight properly */
  /* if font-weight is included, then the pdf export browser will render */
  /* the banner with @media=screen configuration rather than @media=print */
  /* Why is this happening!!!?!?! */
  /* font-weight: 700;*/
}
