.pr-public-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  height: auto;
  width: 85px;
  z-index: 2;
  background: #FFF;
  border-right: 1px solid #C6C6C6;
}
.fixed .pr-public-toolbar {
  -webkit-box-shadow: 0 0 6px -3px #000;
  -moz-box-shadow: 0 0 6px -3px #000;
  -o-box-shadow: 0 0 6px -3px #000;
  box-shadow: 0 0 6px -3px #000;
  position: fixed;
}
.pr-ptb-center .pr-ptb-btn-group {
  display: inline;
  float: left;
}
.pr-ptb-btn-group {
  height: 42px;
  padding: 8px;
  width: 85px;
  border-bottom: 1px solid #C6C6C6;
  text-align: center;
}
