.pr-page-header .label-group > label {
  margin-right: 16px;
  display: block;
}
.pr-page-header .margin-option {
  margin-bottom: 8px;
}
.pr-page-header .margin-option label {
  min-width: 56px;
}
.pr-page-header .advanced-link {
  padding-left: 4px;
}
.pr-page-header .qspinner.simple {
  padding-right: 19px;
}
.pr-page-header .qspinner.simple .btn {
  width: 19px;
  height: 19px;
}
.pr-page-header .qspinner.simple .btn:before {
  top: 6px;
  left: 5px;
}
.pr-page-header .page-header-footer-preview {
  margin-top: 8px;
}
.pr-page-header .page-header-footer-formats {
  background-color: #E8E8E8;
  margin: 0px;
}
.pr-page-header .page-header-footer-input {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
}
.pr-page-header .page-header-footer-input .form-control {
  width: 100%;
}
.pr-page-header .page-header-footer-text-formats {
  display: inline-block;
}
.pr-page-header .page-header-footer-text-formats .font-menu .btn {
  width: 100px;
}
.pr-page-header .page-header-footer-text-formats .size-menu .btn {
  width: 50px;
}
.pr-page-header .header-footer-logo {
  vertical-align: initial;
}
.pr-page-header .custom-logo-input {
  margin: 0 8px;
  height: 35px;
  display: table-cell;
  vertical-align: middle;
}
.pr-page-header .custom-logo-input img {
  height: 35px;
  margin-left: 15px;
}
