.pr-toolbar .filter-button {
  background-color: transparent;
  border-color: transparent;
  position: relative;
}
.pr-options-pane .filter-button .icon-angle-down-sm {
  position: relative;
  left: 115px;
}
.pr-options-pane .remove-filter {
  position: relative;
  top: 2px;
  float: right;
  right: 4px;
  color: #737373;
}
.pr-options-pane .remove-filter:hover {
  cursor: pointer;
  color: #222222;
}
.pr-options-pane .widget-filter-label {
  padding-left: 10px;
  position: relative;
  top: 4px;
}
.filter-count-label {
  background: #A4A4A4;
  position: relative;
  padding: 1px 8px;
  border-radius: 8px;
  width: 22px;
  color: #FFFFFF;
  font-size: 11px;
  margin: -2px 0 0 12px;
}
