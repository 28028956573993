.pr-page-break {
  position: absolute;
  top: -16px;
  left: -1000px;
  right: -1000px;
  text-align: center;
  border-top: 1px dashed #C6C6C6;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.pr-page-break:before {
  content: '';
  position: absolute;
  top: -16px;
  right: 0;
  bottom: 0;
  left: 0;
}
.page-break-label {
  position: relative;
  display: inline-block;
  top: -10px;
  height: 4px;
  line-height: 4px;
  padding: 0 6px;
  font-size: 12px;
  font-weight: 200px;
  color: #A4A4A4;
  background: #F9F9F9;
  cursor: default;
}
.page-break-label .icon {
  position: absolute;
  top: 0px;
  right: -18px;
  width: 24px;
  height: 4px;
  opacity: 0;
  background: #F9F9F9;
}
.page-break-label .icon:before {
  position: relative;
  top: -10px;
}
.pr-page-break:hover .page-break-label .icon {
  opacity: 1;
}
.pr-page-break:hover .page-break-label .icon:hover {
  color: #D13A34;
  cursor: pointer !important;
}
.pr-page-break.removed {
  opacity: 0;
}
