#schedule-email-text-editor {
  text-align: left !important;
  width: 540px;
  height: 125px;
}
#schedule-email-text-editor .ql-editor {
  padding: 15px;
}
#schedule-email-text-editor-toolbar .ql-picker-item[data-value="12px"] {
  font-size: 12px;
}
#schedule-email-text-editor-toolbar .ql-picker-item[data-value="16px"] {
  font-size: 16px;
}
#schedule-email-text-editor-toolbar .ql-picker-item[data-value="20px"] {
  font-size: 20px;
}
