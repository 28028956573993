.schedule-email-modal .modal-header .icon {
  line-height: 37px;
  height: 37px;
  font-size: 18pt;
  margin-right: 8px;
}
.schedule-email-modal .modal-header .modal-title {
  vertical-align: top;
}
.schedule-email-modal .scheduled-emails-container-wrapper {
  position: absolute;
  display: inline-block;
  width: 300px;
  height: 438px;
  vertical-align: top;
  overflow-y: auto;
}
.schedule-email-modal .scheduled-emails-container-wrapper .no-scheduled-emails {
  color: #007ac0;
  position: absolute;
  top: 50%;
  width: 299px;
  padding: 0px 25px;
}
.schedule-email-modal .scheduled-emails-container-wrapper .scheduled-emails-label {
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  padding: 10px 25px;
  background-color: #f7f7f7;
  width: 299px;
}
.schedule-email-modal .scheduled-emails-container-wrapper .scheduled-emails {
  width: 299px;
  overflow: auto;
}
.schedule-email-modal .scheduled-emails-container-wrapper .scheduled-email {
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding: 10px 25px;
  word-wrap: break-word;
}
.schedule-email-modal .scheduled-emails-container-wrapper .scheduled-email:hover {
  background-color: #F7F7F7;
}
.schedule-email-modal .scheduled-emails-container-wrapper .scheduled-email .unsaved-error-icon {
  font-size: 18px;
  vertical-align: top;
}
.schedule-email-modal .scheduled-emails-container-wrapper .scheduled-email.selected-email {
  background-color: #007AC0;
  color: #FFFFFF;
}
.schedule-email-modal .scheduled-emails-container-wrapper .scheduled-email.selected-email:hover {
  background-color: #007AC0;
}
.schedule-email-modal .selected-email-container-wrapper {
  border-left: 1px solid #ccc;
  margin-left: 300px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form {
  min-width: 635px;
  min-height: 375px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .disabled textarea,
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .disabled input,
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .disabled .ql-toolbar,
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .disabled #schedule-email-text-editor {
  color: #999;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .form-input-group {
  margin-top: 15px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-label {
  display: table-cell;
  vertical-align: middle;
  min-width: 95px;
  text-align: right;
  padding-right: 15px;
  cursor: default;
  overflow-wrap: break-word;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-input {
  display: table-cell;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-input .schedule-email-half-width {
  display: inline-block;
  width: 265px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-input .schedule-email-recipients-option {
  position: relative;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-input .schedule-frequency-time {
  width: 130px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-input .schedule-frequency-time-zone {
  width: 130px;
  display: inline-block;
  text-align: center;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-input #schedule-email-from-name-input,
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-input .schedule-frequency-date {
  margin-right: 6px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-input .schedule-frequency-date:hover {
  border-color: #c6c6c6;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-input .schedule-language-picker:hover {
  border-color: #c6c6c6;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-inputs {
  display: table-cell;
  width: 265px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .from-option {
  padding-bottom: 6px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .from-option-label {
  display: block;
  font-size: 14px;
  color: #b8b9be;
  margin-bottom: 0;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .from-option-label .icon {
  bottom: -3px;
  color: black;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .from-option-input {
  width: 100%;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-from-option {
  display: inline-block;
  position: relative;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-from-option #from-email-input {
  padding-right: 25px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-frequency-date:not(.ng-empty) {
  color: #222222;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-language-picker:not(.ng-empty) {
  color: #222222;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-frequency-date {
  vertical-align: middle;
  margin-top: -2px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-language-picker {
  vertical-align: middle;
  margin-top: -2px;
  padding: 0 !important;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-language-picker .multiple-languages {
  padding: 10px 10px 10px 13px;
  width: 265px;
  height: 36px;
  color: #222222;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-language-picker .language-caret {
  float: right;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-full-width,
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-full-width textarea {
  width: 540px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-message .ql-toolbar {
  -webkit-border-top-left-radius: 3px;
  -moz-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-top-right-radius: 3px;
  -moz-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form #schedule-email-text-editor {
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-frequency-date.active:hover,
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-frequency-date.active,
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-language-picker.active:hover,
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-language-picker.active,
.schedule-email-modal .selected-email-container-wrapper .schedule-email-form .schedule-email-message.active {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.schedule-email-modal .modal-footer .left-buttons {
  border-right: 1px solid #ccc;
  position: absolute;
  width: 301px;
  height: 69px;
  overflow: auto;
  bottom: 0px;
  left: 0px;
  z-index: 1;
}
.schedule-email-modal .modal-footer .left-buttons .btn-create-new-email {
  float: left;
  margin-left: 24px;
  margin-top: 16px;
}
.schedule-email-modal .modal-footer .right-buttons {
  margin-left: 300px;
}
.schedule-email-modal .modal-footer .right-buttons .btn-delete-email,
.schedule-email-modal .modal-footer .right-buttons .btn-test-email {
  float: left;
}
.schedule-email-modal .modal-footer .right-buttons .btn-delete-email {
  margin-left: 8px;
}
.send-email-now-modal .email-list-error,
.schedule-email-modal .email-list-error {
  position: absolute;
  top: 5px;
  right: 0px;
}
.send-email-now-modal .send-email-to-form-container {
  position: relative;
}
