.pr-body {
  position: relative;
  padding-top: 84px;
}
.public-report .pr-body {
  padding-top: 0;
}
.pr-body.partialData {
  padding-top: 120px;
}
.public-report .pr-body.partialData {
  padding-top: 36px;
}
.fixed .pr-toc,
.fixed .pr-toolbar,
.fixed .pr-options-pane-wrapper {
  position: fixed;
}
.pr-text-style a:hover span {
  color: #FFFFFF !important;
}
.widget-count-limit-warning-modal .modal-dialog {
  max-width: 30%;
}
/**
 * Public Report
 */
#public-cover {
  display: none;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  position: fixed;
}
