.hidden-renderer {
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
/*
ng-view {
	visibility: hidden;
}

.hidden-renderer {
	overflow: visible;
	position: absolute;
	top: 0;

    .pr-widget-chunk {
		position: relative;
		left: 110%;
		top: -5091px;
		border-bottom: 1px solid #333;
	}
}
*/
