.pr-options-pane-wrapper {
  position: absolute;
  top: 84px;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #F2F4F8;
  padding-bottom: 88px;
  border-left: 1px solid #C6C6C6;
  width: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  -ms-transition: width 0.3s;
  -o-transition: width 0.3s;
  transition: width 0.3s;
}
.pr-options-pane-wrapper.active {
  width: 286px;
}
.partialData .pr-options-pane-wrapper {
  top: 120px;
}
.pr-options-pane {
  min-height: 100%;
  padding: 20px;
  /* room to show footer */
  width: 286px;
}
.pr-options-pane .btn-full {
  width: 100%;
}
/* override ripsaw trying to set the height on widget contents */
.pr-options-pane .widget-contents {
  height: auto !important;
}
.pr-options-pane.collapsed {
  width: 32px;
}
.pr-options-pane.collapsed:hover {
  width: 286px;
}
.widget-style-selector .widget-text-option {
  display: inline-block;
}
.widget-style-selector .widget-text-option .widget-font-selector .btn {
  /* Shorten the width of the font selector to make room for other buttons inline (and padding)
			 * 40 px for padding (pr-options-pane above)
			 * 26 px for 2 buttons (bold and color)
			 * 50 px for size selector (below) */
  width: 144px;
}
.widget-style-selector .widget-text-option .widget-font-size-selector .btn {
  width: 50px;
}
.widget-style-selector label {
  display: inline-block;
  color: #737373;
  font-weight: 300;
}
.widget-style-selector .right {
  display: block;
  float: right;
}
