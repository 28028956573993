body {
  margin: 0;
  padding: 0;
}
body .qmenu {
  max-height: 75vh !important;
}
ul {
  margin: 0;
  padding: 0;
}
.link {
  color: #428bca;
}
.link:hover {
  cursor: pointer;
  color: #2a6496;
  text-decoration: underline;
}
/* This is a hack so that safari does not jump to the top of the page when a user opens a searchable dropdown menu */
.safari .qmenu .search-box .form-control {
  width: auto !important;
}
