.pr-graphic-library .folder-container {
  display: block;
  position: relative;
  clear: left;
}
.pr-graphic-library .folder-container > label {
  font-size: 19px;
  border-bottom: 1px solid #737373;
}
.pr-graphic-library .folder-container > img {
  max-width: 70px;
  max-height: 70px;
  margin-left: 50px;
  cursor: pointer;
}
.pr-graphic-library table {
  display: inline-block;
}
.pr-graphic-library td {
  padding-right: 12px;
}
.pr-graphic-library .upload-image-btn {
  float: right;
  margin-top: 3px;
}
.pr-graphic-library .search {
  float: right;
  position: relative;
}
.pr-graphic-library .search .search-input {
  padding-left: 25px;
}
.pr-graphic-library .search .icon-search {
  position: absolute;
  left: 4px;
  top: 7px;
  color: #C6C6C6;
}
.pr-graphic-library .no-image-found {
  text-align: center;
  padding-top: 30px;
}
.pr-graphic-library .thumb-container {
  float: left;
  margin-right: 8px;
  margin-top: 10px;
  max-width: 100px;
  max-height: 100px;
  padding: 4px;
}
.pr-graphic-library .thumb-container .graphic-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
