.pr-graphic-library-image-upload .input-row {
  padding-bottom: 5px;
  padding-top: 5px;
}
.pr-graphic-library-image-upload .input-row .input-group {
  display: inline-flex;
}
.pr-graphic-library-image-upload .file-size-alert {
  color: #D13A34;
  font-size: 12pt;
}
