.hide-report {
  margin-top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #D13A34;
}
.hide-report-toolbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  height: 84px;
  width: 85px;
  z-index: 2;
  background: #FFF;
  border-right: 1px solid #C6C6C6;
}
@media print {
  .hide-report-toolbar {
    display: none;
  }
}
