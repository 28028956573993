/* list should not have margin or padding */
ul,
ol {
  margin: 0;
  padding: 0;
}
/* Drag Handle for sorting */
.drag-handle {
  position: absolute;
  top: 7px;
  left: 0;
  display: none;
  border-top: 5px double #CCC;
  border-bottom: 5px double #CCC;
  width: 10px;
  height: 11px;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  cursor: grab;
}
.folder-menu-label {
  position: absolute;
  top: 5px;
  left: 5px;
}
