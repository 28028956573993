/*
 * NOTE: don't use class `pr-widget` and Ad Blocker will hide it
 */
.widget-padding:not(.TEXT):not(.TABLE) {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 1px;
  left: 0;
}
.widget-padding.TEXT,
.widget-padding.TABLE {
  width: calc(100% - 1px);
  height: calc(100% - 1px);
}
/**
 * Public Report
 */
.show-outlines .public-report .widget-padding {
  outline: none;
}
.show-outlines .widget-padding {
  outline: 1px solid #E8E8E8;
  outline-color: rgba(0, 0, 0, 0.1);
}
pr-widget {
  position: absolute;
}
pr-widget:not(.selected):not(.active) {
  -webkit-transition: top 0.1s linear, left 0.1s linear;
  -moz-transition: top 0.1s linear, left 0.1s linear;
  -ms-transition: top 0.1s linear, left 0.1s linear;
  -o-transition: top 0.1s linear, left 0.1s linear;
  transition: top 0.1s linear, left 0.1s linear;
}
pr-widget.active,
pr-widget.selected {
  z-index: 1;
}
pr-widget.active .widget-padding,
pr-widget.selected .widget-padding {
  outline: 1px solid #0B6ED0;
}
pr-widget.active .pr-widget-wrapper,
pr-widget.selected .pr-widget-wrapper {
  outline: 1px dashed #0B6ED0;
}
pr-widget .top-buttons {
  top: -34px;
}
pr-widget .bottom-buttons {
  bottom: -7px;
}
pr-widget .top-buttons,
pr-widget .bottom-buttons {
  display: none;
  position: absolute;
  left: 50%;
}
pr-widget .top-buttons .inner-buttons,
pr-widget .bottom-buttons .inner-buttons {
  height: 0px;
  margin-left: -50%;
  white-space: nowrap;
}
pr-widget.selected .top-buttons,
pr-widget.selected .bottom-buttons {
  display: block;
}
pr-widget .conditional-display-indicator .icon {
  cursor: pointer !important;
  z-index: 2;
  float: right;
}
pr-widget .conditional-display-indicator .icon.hide-in-public {
  color: #D13A34;
}
pr-text-body.fixed {
  display: inline-block;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.multiple-selected pr-widget.selected .top-buttons,
.multiple-selected pr-widget.selected .bottom-buttons {
  display: none;
}
.simple-table tbody tr:hover th,
.simple-table tbody tr:hover td {
  background: transparent;
}
.pr-widget-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 16px;
}
.pr-widget-wrapper .pr-hidden-widget,
.pr-widget-wrapper .pr-widget-body,
.pr-widget-wrapper pr-spacer-body {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.pr-widget-wrapper pr-text-body .pr-widget-chunk,
.pr-widget-wrapper pr-table-body .pr-widget-chunk,
.pr-widget-wrapper pr-text-body .pr-widget-body,
.pr-widget-wrapper pr-table-body .pr-widget-body {
  position: relative;
  min-height: 16px;
}
.pr-widget-wrapper .widget-indicator {
  background: #E8E8E8;
  height: 100%;
  position: relative;
  min-height: 30px;
}
/*
 * Only hide the content on the page, not in the TOC
 */
.pr-pages .pr-widget-wrapper .hidden-content {
  opacity: 0;
}
pr-widget-shadow {
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  -webkit-transition: top 0.1s linear, left 0.1s linear, width 0.1s linear, height 0.1s linear;
  -moz-transition: top 0.1s linear, left 0.1s linear, width 0.1s linear, height 0.1s linear;
  -ms-transition: top 0.1s linear, left 0.1s linear, width 0.1s linear, height 0.1s linear;
  -o-transition: top 0.1s linear, left 0.1s linear, width 0.1s linear, height 0.1s linear;
  transition: top 0.1s linear, left 0.1s linear, width 0.1s linear, height 0.1s linear;
}
.pr-hidden-widget .spinner {
  display: none;
}
.simple-table th {
  font-weight: 200;
}
.simple-table th,
.simple-table td {
  padding: 8px;
}
.simple-table:not(.records-table) tr th:first-child,
.simple-table:not(.records-table) tr td:first-child {
  padding-left: 0px !important;
}
.simple-table:not(.records-table) tr th:last-child,
.simple-table:not(.records-table) tr td:last-child {
  padding-right: 0px !important;
}
.eng-grid .single-grid-table {
  padding-top: 0px !important;
}
