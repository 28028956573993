.pr-import-report .source-map {
  padding: 5px 0px;
}
.pr-import-report .source-label {
  width: 250px;
  display: inline-block;
}
.pr-import-report .source-menu {
  width: 200px;
  vertical-align: top;
}
.pr-import-report .instruction {
  margin-bottom: 20px;
  max-width: 500px;
}
.pr-import-report .status {
  border: 1px solid transparent;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  max-width: 500px;
}
.pr-import-report .pending {
  background-color: #CCE4F2;
  border-color: #085CAF;
  color: #085CAF;
}
.pr-import-report .failed {
  background-color: #F5D7D6;
  border-color: #BB342E;
  color: #BB342E;
}
.pr-import-report .succeeded {
  background-color: #CCE9DC;
  border-color: #008448;
  color: #008448;
}
