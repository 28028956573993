.display-logic-button {
  width: 40px;
  float: left;
}
.display-logic-label {
  padding-left: 10px;
  position: relative;
  top: 4px;
}
.remove-display-logic {
  position: relative;
  top: 2px;
  float: right;
  right: 4px;
  color: #737373;
}
.remove-display-logic:hover {
  cursor: pointer;
  color: #222222;
}
