.pr-text-styles td {
  padding-bottom: 15px;
}
.pr-text-styles td:first-child {
  width: 40%;
  padding-right: 8px;
}
.pr-text-styles .font-menu .btn {
  width: 90px;
}
.pr-text-styles .size-menu .btn {
  width: 56px;
}
