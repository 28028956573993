.create-report .modal-body {
  padding: 0px;
  min-width: 621px;
  height: 500px;
}
.create-report .sidebar {
  padding-top: 8px;
  position: fixed;
  width: 207px;
  height: 500px;
  border: none;
  -webkit-box-shadow: inset -1px 0px 0px 0px #C6C6C6;
  -moz-box-shadow: inset -1px 0px 0px 0px #C6C6C6;
  -o-box-shadow: inset -1px 0px 0px 0px #C6C6C6;
  box-shadow: inset -1px 0px 0px 0px #C6C6C6;
  background: #F2F5F8;
}
.create-report .sidebar .btn {
  width: 206px;
  margin: 0;
  padding: 12px 0 10px 15px;
  text-align: left;
  background: #F2F4F8;
  border-radius: 0;
  border: none;
  display: flex;
  height: 40px;
}
.create-report .sidebar .btn.active {
  background: #0B6ED0;
  color: #FFFFFF;
}
.create-report .sidebar .btn:hover:not(.active) {
  background: #737373;
  color: #FFFFFF;
}
.create-report .content {
  margin-left: 207px;
  min-height: 300px;
  padding: 24px;
}
.create-report .content label {
  color: #737373;
}
.create-report .content .convert-limit {
  font-style: italic;
  color: #A4A4A4;
}
@media screen and (max-height: 760px) {
  .create-report .modal-body {
    height: inherit;
  }
  .create-report .sidebar {
    position: absolute;
    top: 0;
    bottom: 0;
    height: inherit;
  }
}
