.custom-metrics-input {
  width: 80%;
  min-width: none;
}
.custom-metrics-equation-result {
  pointer-events: none;
  max-width: 35%;
}
.custom-metrics-insert-button {
  width: auto;
}
