.pr-report {
  position: relative;
  overflow: hidden;
  padding: 0 286px 0 0;
  background: #F9F9F9;
}
.public-report .pr-report {
  pointer-events: none;
  padding: 0;
}
.public-report .pr-report .table-text a {
  pointer-events: all;
}
