.pr-alert-banner {
  position: absolute;
  top: 84px;
  right: 0;
  left: 0;
  height: 36px;
  z-index: 2;
  background-color: #F3E350;
  border-bottom: 1px solid #ECD746;
  text-align: center;
  padding: 8px;
}
.pr-alert-banner .container {
  vertical-align: middle;
  display: inline-block;
  font-size: 14px;
  top: 10px;
  margin-top: -8px;
}
.pr-alert-banner .text {
  font-family: QualtricsGrotesque-SemiBold;
  padding: 9px 0px;
}
.pr-alert-banner.public {
  top: 0px;
  left: 85px;
}
.pr-alert-banner .icon {
  top: 5px;
}
.fixed .pr-alert-banner {
  position: fixed;
  -webkit-box-shadow: 0 0 6px -3px #000;
  -moz-box-shadow: 0 0 6px -3px #000;
  -o-box-shadow: 0 0 6px -3px #000;
  box-shadow: 0 0 6px -3px #000;
}
