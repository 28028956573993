/*
 * Most styles are actually implemented in the associated transformers (eg core.less)
 */
pr-widget {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.public-report pr-widget {
  cursor: default;
}
pr-widget * {
  cursor: inherit !important;
}
.inheritCursor * {
  cursor: inherit !important;
}
.cursor-default {
  cursor: default;
}
.cursor-default * {
  cursor: inherit !important;
}
.cursor-grab {
  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.public-report .cursor-grab {
  cursor: default;
}
.cursor-grab * {
  cursor: inherit !important;
}
.cursor-grabbing {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.cursor-grabbing * {
  cursor: inherit !important;
}
.cursor-text {
  cursor: text;
}
.cursor-text * {
  cursor: inherit !important;
}
.cursor-resizing * {
  cursor: inherit !important;
}
