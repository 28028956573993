.results-items {
  margin-top: 8px;
}
.results-items .search-input {
  z-index: 5;
  width: 100%;
  padding-left: 35px;
  border-radius: 3px 3px 0 0;
}
.results-items .icon-search {
  top: -30px;
  margin-left: 6px;
  z-index: 6;
  color: #737373;
}
.results-items .select-items {
  border: 1px solid #C6C6C6;
  border-radius: 0 0 3px 3px;
  margin-top: -28px;
}
.results-items .select-items .select-all-items {
  background: #E8E8E8;
  padding: 6px 8px 0 8px;
}
.results-items .select-items .item-count {
  float: right;
}
.results-items .select-items .hidden {
  color: #D7D7D7;
}
.results-items .select-items .item-list {
  padding: 0 8px;
  overflow-y: scroll;
  max-height: 120px;
}
.results-items .select-items .item-list li {
  list-style: none;
  padding-top: 5px;
  border-bottom: 1px solid #D7D7D7;
}
.results-items .select-items .item-list li:last-child {
  border: none;
}
.results-items .select-items .item-list .empty-search-text {
  font-style: italic;
  color: #A4A4A4;
}
