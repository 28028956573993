.pr-widget-count-warning-banner {
  position: absolute;
  top: 84px;
  right: 0;
  left: 0;
  height: 36px;
  z-index: 2;
  background-color: #FFFFFF;
  border: 1px solid #FB9C00;
  border-radius: 5px;
  text-align: center;
  padding: 8px;
  margin: 20px 350px 0 350px;
}
.pr-widget-count-warning-banner .container {
  vertical-align: middle;
  display: inline-block;
  font-size: 14px;
  top: 10px;
  margin-top: -8px;
  overflow: wrap;
}
.pr-widget-count-warning-banner .text {
  font-family: QualtricsGrotesque-SemiBold;
  padding: 9px 0px;
}
.pr-widget-count-warning-banner .icon {
  color: #FB9C00;
  top: 5px;
}
.fixed .pr-widget-count-warning-banner {
  position: fixed;
  -webkit-box-shadow: 0 0 6px -3px #000;
  -moz-box-shadow: 0 0 6px -3px #000;
  -o-box-shadow: 0 0 6px -3px #000;
  box-shadow: 0 0 6px -3px #000;
}
