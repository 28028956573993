.display-logic-modal-body .sidebar-section .side-bar-info-text {
  margin: 10px 0 10px 15px;
  color: #A4A4A4;
}
.display-logic-modal-body .display-logic-preview .action-and-item {
  margin-bottom: 10px;
}
.display-logic-modal-body .display-logic-preview .action-and-item .action-options {
  width: 130px;
}
.display-logic-modal-body .display-logic-preview .action-and-item .item-options {
  min-width: 175px;
  max-width: 200px;
}
.display-logic-modal-body .display-logic-preview .preview-info-text {
  margin: 12px 0 12px 1px;
  color: #A4A4A4;
}
.no-display-logic-view {
  display: table;
  width: 100%;
  text-align: center;
}
.no-display-logic-view .display-logic-table-cell-aligned {
  display: table-cell;
  vertical-align: middle;
}
.no-display-logic-view .display-logic-table-cell-aligned .no-display-logic-text {
  margin-bottom: 8px;
  color: #A4A4A4;
}
