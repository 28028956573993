.ql-container {
  font-family: inherit;
}
@font-face {
  font-family: Amaranth;
  src: url(/static/reports/fonts/amaranth-Regular.12ff4d7b6901dc447d18f0bd9cf74f96.woff) format('woff'), url(/static/reports/fonts/amaranth-Regular.714eb884335e287e84f718d6febb436e.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Amaranth;
  src: url(/static/reports/fonts/amaranth-Bold.53761f3f4e6ee7139a5b091344e5e761.woff) format('woff'), url(/static/reports/fonts/amaranth-Bold.02ef93a1eb856cf73da43d332465801b.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Amaranth;
  src: url(/static/reports/fonts/amaranth-BoldItalic.5cc0b66152004243f036d3f14ca88eb7.woff) format('woff'), url(/static/reports/fonts/amaranth-BoldItalic.f67fbe8146e298d289ddfad53b0042c3.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Amaranth;
  src: url(/static/reports/fonts/amaranth-Italic.0e5f512d219316e48a983e6e5bc9174f.woff) format('woff'), url(/static/reports/fonts/amaranth-Italic.9790e5eaf33e077b4084777577462354.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-amaranth,
.ql-container .ql-font-amaranth * {
  font-family: "Amaranth", sans-serif;
}
@font-face {
  font-family: Anonymous Pro;
  src: url(/static/reports/fonts/anonymousPro-Regular.840899ade60bada8f9b9b7aaa54b3616.woff) format('woff'), url(/static/reports/fonts/anonymousPro-Regular.97be752ef119600da7ce506ae732e77d.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Anonymous Pro;
  src: url(/static/reports/fonts/anonymousPro-Bold.e2424790e29ba5053084e24638fce849.woff) format('woff'), url(/static/reports/fonts/anonymousPro-Bold.7aa70cbdab40d026887e3f66d7489eca.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Anonymous Pro;
  src: url(/static/reports/fonts/anonymousPro-BoldItalic.029f710ebb97701e8c301ed345202738.woff) format('woff'), url(/static/reports/fonts/anonymousPro-BoldItalic.d9adb85c39c8dac358f255f18716fdc3.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Anonymous Pro;
  src: url(/static/reports/fonts/anonymousPro-Italic.8de34b295c3fe5b8f6bd90a2ba0f9977.woff) format('woff'), url(/static/reports/fonts/anonymousPro-Italic.127204d402a216af565e547b57854c89.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-anonymous_pro,
.ql-container .ql-font-anonymous_pro * {
  font-family: "Anonymous Pro", serif;
}
@font-face {
  font-family: Arvo;
  src: url(/static/reports/fonts/arvo-Regular.d972d8be577960e559310ec67cd7caff.woff) format('woff'), url(/static/reports/fonts/arvo-Regular.336a72d20bf4b2ce5111dfd3ea19bdd1.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Arvo;
  src: url(/static/reports/fonts/arvo-Bold.2e81384e6e73e55db9ecec4d6595d886.woff) format('woff'), url(/static/reports/fonts/arvo-Bold.40514d3b8c506c4b4f2f3063437c74a3.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Arvo;
  src: url(/static/reports/fonts/arvo-BoldItalic.21f413fb7160be9193c6a4731769dc78.woff) format('woff'), url(/static/reports/fonts/arvo-BoldItalic.74c9c9fa941514cb54a7c6c900d29027.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Arvo;
  src: url(/static/reports/fonts/arvo-Italic.8dd57021d48e43a17d3d2a88690d8122.woff) format('woff'), url(/static/reports/fonts/arvo-Italic.d7728eb97e39a30a472f834d542f1a3a.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-arvo,
.ql-container .ql-font-arvo * {
  font-family: "Arvo", serif;
}
@font-face {
  font-family: Averia Serif Libre;
  src: url(/static/reports/fonts/averiaSerifLibre-Regular.0c2d2abee0b2532f21e1e7d7d44d8cbe.woff) format('woff'), url(/static/reports/fonts/averiaSerifLibre-Regular.ee9f4c0d6c8204898b03b4050764a9cc.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Averia Serif Libre;
  src: url(/static/reports/fonts/averiaSerifLibre-Bold.6b15552602b5d56780dd9156f4d1302b.woff) format('woff'), url(/static/reports/fonts/averiaSerifLibre-Bold.fd2666a61b0e738af1ba29dcab6809b8.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Averia Serif Libre;
  src: url(/static/reports/fonts/averiaSerifLibre-BoldItalic.e4ebacbc0a3307410cf1cf379f4f6fb6.woff) format('woff'), url(/static/reports/fonts/averiaSerifLibre-BoldItalic.1536d283467753d542a95c96cf892abb.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Averia Serif Libre;
  src: url(/static/reports/fonts/averiaSerifLibre-Italic.b2444ff0bbbf27b21f8f9bf74488e536.woff) format('woff'), url(/static/reports/fonts/averiaSerifLibre-Italic.5163d724bc637e8cfd33cbc5276f0f80.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-averia_serif_libre,
.ql-container .ql-font-averia_serif_libre * {
  font-family: "Averia Serif Libre", serif;
}
@font-face {
  font-family: Barlow Condensed;
  src: url(/static/reports/fonts/barlowCondensed-Regular.98f76d3b3020ab1ebfc45e6aa276fd8f.woff) format('woff'), url(/static/reports/fonts/barlowCondensed-Regular.94998082a1a881627a080e78ec600600.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Barlow Condensed;
  src: url(/static/reports/fonts/barlowCondensed-Bold.6b08b77e06b80a01f2c69a2eeeb554e8.woff) format('woff'), url(/static/reports/fonts/barlowCondensed-Bold.ac9e49a49f3fe1acd6d58672dec2a7ff.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Barlow Condensed;
  src: url(/static/reports/fonts/barlowCondensed-BoldItalic.d8d1dad249adc6774e865e1220c9d54f.woff) format('woff'), url(/static/reports/fonts/barlowCondensed-BoldItalic.910246577285626c64a710fe104e828d.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Barlow Condensed;
  src: url(/static/reports/fonts/barlowCondensed-Italic.5a62141dfe0a6cb4a9b4954c86aa6990.woff) format('woff'), url(/static/reports/fonts/barlowCondensed-Italic.f5d98a2631cd7e65f314fe2d5821d0de.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-barlow_condensed,
.ql-container .ql-font-barlow_condensed * {
  font-family: "Barlow Condensed", sans-serif;
}
@font-face {
  font-family: Crimson Text;
  src: url(/static/reports/fonts/crimsonText-Regular.2728a4120e7021cfed536929ffb5ad92.woff) format('woff'), url(/static/reports/fonts/crimsonText-Regular.6195353b1df70e0b79679234acb4a4be.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Crimson Text;
  src: url(/static/reports/fonts/crimsonText-Bold.e4e66c62ad2f1651a66470cf0ec18a65.woff) format('woff'), url(/static/reports/fonts/crimsonText-Bold.b308656fa8a3cda57555f4e71d038702.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Crimson Text;
  src: url(/static/reports/fonts/crimsonText-BoldItalic.16977c5d51d25c9083368797566f1909.woff) format('woff'), url(/static/reports/fonts/crimsonText-BoldItalic.b79c0dafcb7e059bb486b506362855e9.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Crimson Text;
  src: url(/static/reports/fonts/crimsonText-Italic.0c503eb209806a4d203aa60b210cd9d3.woff) format('woff'), url(/static/reports/fonts/crimsonText-Italic.3bb46ecd000153dc08b82b8d27f148ed.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-crimson_text,
.ql-container .ql-font-crimson_text * {
  font-family: "Crimson Text", serif;
}
@font-face {
  font-family: Economica;
  src: url(/static/reports/fonts/economica-Regular.52e83241a84b1a01080bb16ea7e6bf63.woff) format('woff'), url(/static/reports/fonts/economica-Regular.401ea5b3fae689be8b3bd53a6205d268.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Economica;
  src: url(/static/reports/fonts/economica-Bold.221f6f31422827d199aaf75141f0e89d.woff) format('woff'), url(/static/reports/fonts/economica-Bold.a0ba9e905d291e1bd0df705de3d3b0e1.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Economica;
  src: url(/static/reports/fonts/economica-BoldItalic.a47655b2ff1f8ac7161aa8c2016ddfcd.woff) format('woff'), url(/static/reports/fonts/economica-BoldItalic.0f8fa3bcea0562efad93fbf326d98bd1.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Economica;
  src: url(/static/reports/fonts/economica-Italic.1edd4ffbb25b03b987f6ab861cfed906.woff) format('woff'), url(/static/reports/fonts/economica-Italic.0c5d27888aaf8ff3c71f6a97ccf4757f.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-economica,
.ql-container .ql-font-economica * {
  font-family: "Economica", sans-serif;
}
@font-face {
  font-family: Gentium Book Basic;
  src: url(/static/reports/fonts/gentiumBookBasic-Regular.7e5ce6fa26aa9918c675aa3566a56e0d.woff) format('woff'), url(/static/reports/fonts/gentiumBookBasic-Regular.e0765c7720f4d8a3422172b9706bad36.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Gentium Book Basic;
  src: url(/static/reports/fonts/gentiumBookBasic-Bold.47ba0b5c6fd645ea3e6761f054ca8f59.woff) format('woff'), url(/static/reports/fonts/gentiumBookBasic-Bold.d4684f33db7a9bf6e67203b816058aab.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Gentium Book Basic;
  src: url(/static/reports/fonts/gentiumBookBasic-BoldItalic.d4fe7ab662a6727c00a1a569923e665f.woff) format('woff'), url(/static/reports/fonts/gentiumBookBasic-BoldItalic.509b270b240ebb401536a9d02046ed41.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Gentium Book Basic;
  src: url(/static/reports/fonts/gentiumBookBasic-Italic.9646b5d9afb3fef06b1c50ebbfb48f9d.woff) format('woff'), url(/static/reports/fonts/gentiumBookBasic-Italic.33b41e5eff6c93766bd1c2e35120984d.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-gentium_book_basic,
.ql-container .ql-font-gentium_book_basic * {
  font-family: "Gentium Book Basic", serif;
}
@font-face {
  font-family: IBM Plex Mono;
  src: url(/static/reports/fonts/ibmPlexMono-Regular.fa067264410ebf687ecca103e1845b4a.woff) format('woff'), url(/static/reports/fonts/ibmPlexMono-Regular.a299eae4867d82cc48bb7936e782b7d9.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: IBM Plex Mono;
  src: url(/static/reports/fonts/ibmPlexMono-Bold.4cd64029ead0c512ca9bb9e83910dd07.woff) format('woff'), url(/static/reports/fonts/ibmPlexMono-Bold.d1eeabb1bcccd054b2ca01ab0329d103.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: IBM Plex Mono;
  src: url(/static/reports/fonts/ibmPlexMono-BoldItalic.14233c153355f1e3c22533bc4879e5f5.woff) format('woff'), url(/static/reports/fonts/ibmPlexMono-BoldItalic.e7644aa64ee51bdadb3794e054076292.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: IBM Plex Mono;
  src: url(/static/reports/fonts/ibmPlexMono-Italic.d12c68599f375fb6e94ca1b4b25950e0.woff) format('woff'), url(/static/reports/fonts/ibmPlexMono-Italic.f14eb44fb1fcc02c282efaa57e34ed04.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-ibm_plex_mono,
.ql-container .ql-font-ibm_plex_mono * {
  font-family: "IBM Plex Mono", monospace, sans-serif;
}
@font-face {
  font-family: IBM Plex Serif;
  src: url(/static/reports/fonts/ibmPlexSerif-Regular.89c577fe4f7b7f9eb986834071d7ae0f.woff) format('woff'), url(/static/reports/fonts/ibmPlexSerif-Regular.14af344c09122ffd79402b0c0b4060e1.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: IBM Plex Serif;
  src: url(/static/reports/fonts/ibmPlexSerif-Bold.eaee60c6bddc7f51a0e7796fe8bcf980.woff) format('woff'), url(/static/reports/fonts/ibmPlexSerif-Bold.f8274fa5788c0967b7604af969ab3b1b.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: IBM Plex Serif;
  src: url(/static/reports/fonts/ibmPlexSerif-BoldItalic.569a356cf5a61239bbaf411f9a3515f9.woff) format('woff'), url(/static/reports/fonts/ibmPlexSerif-BoldItalic.3e2702cda830423572400d1a603c4811.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: IBM Plex Serif;
  src: url(/static/reports/fonts/ibmPlexSerif-Italic.df510cf6a03b847cf68d437b98e5cd4d.woff) format('woff'), url(/static/reports/fonts/ibmPlexSerif-Italic.fe1602a6e1ed16882fd4e1ba34934dcc.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-ibm_plex_serif,
.ql-container .ql-font-ibm_plex_serif * {
  font-family: "IBM Plex Serif", serif;
}
@font-face {
  font-family: Josefin Sans;
  src: url(/static/reports/fonts/josefinSans-Regular.6537892df3329ee8024ec1bd09ca6122.woff) format('woff'), url(/static/reports/fonts/josefinSans-Regular.a80a033f0cf0b8186287fa277c334efb.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Josefin Sans;
  src: url(/static/reports/fonts/josefinSans-Bold.816542f10b7e97e5deb159c1aec84976.woff) format('woff'), url(/static/reports/fonts/josefinSans-Bold.ed780de1b24634dbc7490a9caff2e35d.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Josefin Sans;
  src: url(/static/reports/fonts/josefinSans-BoldItalic.2c7a8aa0ca92fbfac79d12b811092b04.woff) format('woff'), url(/static/reports/fonts/josefinSans-BoldItalic.052635dce76754ce05c0a2473edf9d59.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Josefin Sans;
  src: url(/static/reports/fonts/josefinSans-Italic.475261813adba6463f0ffdb67ca90755.woff) format('woff'), url(/static/reports/fonts/josefinSans-Italic.03176f6f2001235d00339c763f7b93d0.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-josefin-sans,
.ql-container .ql-font-josefin-sans * {
  font-family: "Josefin Sans", sans-serif;
}
@font-face {
  font-family: Josefin Slab;
  src: url(/static/reports/fonts/josefinSlab-Regular.d9e25682e76e956e0c040b1c33259380.woff) format('woff'), url(/static/reports/fonts/josefinSlab-Regular.d3dc685fb199d30b1470bd26edf9689f.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Josefin Slab;
  src: url(/static/reports/fonts/josefinSlab-Bold.ce187cdb2b455855792357e946525801.woff) format('woff'), url(/static/reports/fonts/josefinSlab-Bold.188c3dc648a7fd47f712311539a40edd.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Josefin Slab;
  src: url(/static/reports/fonts/josefinSlab-BoldItalic.891781997b489c3e507bb55deec5cf57.woff) format('woff'), url(/static/reports/fonts/josefinSlab-BoldItalic.7365ba297cccadd8a861c48c4226b11a.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Josefin Slab;
  src: url(/static/reports/fonts/josefinSlab-Italic.604a56694fb8e3ad3124e67150e07f19.woff) format('woff'), url(/static/reports/fonts/josefinSlab-Italic.da17ba0f008cb587b1984b0958dd1a27.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-josefin-slab,
.ql-container .ql-font-josefin-slab * {
  font-family: "Josefin Slab", serif;
}
@font-face {
  font-family: Lora;
  src: url(/static/reports/fonts/lora-Regular.06b572af3d54cd72dfaa0028e85b2880.woff) format('woff'), url(/static/reports/fonts/lora-Regular.6fcf94b8847dbad082108c490535457d.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Lora;
  src: url(/static/reports/fonts/lora-Bold.d4d81e202ab1792a05eea0622fea3fb8.woff) format('woff'), url(/static/reports/fonts/lora-Bold.e99c68d4210c2faa09b7590e8ba57c38.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Lora;
  src: url(/static/reports/fonts/lora-BoldItalic.d5e324955afe706155c396af4ceb8bf8.woff) format('woff'), url(/static/reports/fonts/lora-BoldItalic.08487c8ce1cc4098cd933756c7e70096.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Lora;
  src: url(/static/reports/fonts/lora-Italic.df42af4acd9b5ceef4d7c5579de4a180.woff) format('woff'), url(/static/reports/fonts/lora-Italic.0568fd34a2db9e7e0d974ca7b75920de.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-lora,
.ql-container .ql-font-lora * {
  font-family: "Lora", serif;
}
@font-face {
  font-family: Lobster Two;
  src: url(/static/reports/fonts/lobsterTwo-Regular.61cde93323b6335b3fe759b76be4f41d.woff) format('woff'), url(/static/reports/fonts/lobsterTwo-Regular.cbb3b70d5d3d64e5ac0d746e944a51a2.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Lobster Two;
  src: url(/static/reports/fonts/lobsterTwo-Bold.b41a99e4a71d0dd9ea714d67c03dba2e.woff) format('woff'), url(/static/reports/fonts/lobsterTwo-Bold.e1c37dea2f8f670e0acc91349e1f7cbf.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Lobster Two;
  src: url(/static/reports/fonts/lobsterTwo-BoldItalic.a77c4696541404371d87598f016aa771.woff) format('woff'), url(/static/reports/fonts/lobsterTwo-BoldItalic.6b39a8c59ab4f33fcc6076c08880439b.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Lobster Two;
  src: url(/static/reports/fonts/lobsterTwo-Italic.0361dacfd471d8e9548ebc83dd3e2344.woff) format('woff'), url(/static/reports/fonts/lobsterTwo-Italic.22dd5d04424faf8f821edf61ded0cf46.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-lobster_two,
.ql-container .ql-font-lobster_two * {
  font-family: "Lobster Two", serif;
}
@font-face {
  font-family: Merriweather;
  src: url(/static/reports/fonts/merriweather-Regular.2b82823cee7109221b3414dd5fa8c058.woff) format('woff'), url(/static/reports/fonts/merriweather-Regular.58fea50718950b4879e8c054ec09fd93.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Merriweather;
  src: url(/static/reports/fonts/merriweather-Bold.0d66b1d78752c4996699bc51b00a8585.woff) format('woff'), url(/static/reports/fonts/merriweather-Bold.8d62929afcca3e8a8b90e6e280ecb7e0.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Merriweather;
  src: url(/static/reports/fonts/merriweather-BoldItalic.cd6d89267dafecef8d7a952c69d334d3.woff) format('woff'), url(/static/reports/fonts/merriweather-BoldItalic.89179a1c446815565bc6e67ce3e9e049.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Merriweather;
  src: url(/static/reports/fonts/merriweather-Italic.5fc40306a72c28a38285dd1eaab565f8.woff) format('woff'), url(/static/reports/fonts/merriweather-Italic.f84237dbc0dc6c79cb87086f06db132c.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-merriweather,
.ql-container .ql-font-merriweather * {
  font-family: "Merriweather", serif;
}
@font-face {
  font-family: Montserrat;
  src: url(/static/reports/fonts/montserrat-Regular.1119d054247d1182914a754403e3af6a.woff) format('woff'), url(/static/reports/fonts/montserrat-Regular.bef7e247546617f705fd7b8e7ac4a962.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Montserrat;
  src: url(/static/reports/fonts/montserrat-Bold.cae084653e89b77f211b5d026859a4ac.woff) format('woff'), url(/static/reports/fonts/montserrat-Bold.30329db009e4a9500bf4a44b838f6fdc.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Montserrat;
  src: url(/static/reports/fonts/montserrat-BoldItalic.9f97b0b6dcf5a9b73fd64a6be4d3251c.woff) format('woff'), url(/static/reports/fonts/montserrat-BoldItalic.2d05a6cd2841ac20d3d241c3446d91db.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Montserrat;
  src: url(/static/reports/fonts/montserrat-Italic.083c3bf38db0910d167e7a611f60bbea.woff) format('woff'), url(/static/reports/fonts/montserrat-Italic.42831bdd9aea9ff5eec0debc493081c9.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-montserrat,
.ql-container .ql-font-montserrat * {
  font-family: "Montserrat", sans-serif;
}
@font-face {
  font-family: Noto Serif;
  src: url(/static/reports/fonts/notoSerif-Regular.18e26ed0c608d45ce2d92831bcf59662.woff) format('woff'), url(/static/reports/fonts/notoSerif-Regular.861aca16787e074475baa4e80f73c098.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Noto Serif;
  src: url(/static/reports/fonts/notoSerif-Bold.5d22f37462b12682e6a83e6d716af715.woff) format('woff'), url(/static/reports/fonts/notoSerif-Bold.0265a15b68509a2c9dff8715fbc8d288.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Noto Serif;
  src: url(/static/reports/fonts/notoSerif-BoldItalic.05ad63969504bbe5b25b872bce0db212.woff) format('woff'), url(/static/reports/fonts/notoSerif-BoldItalic.73990f6ce21c4d94ae1a9f480c48d0b8.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Noto Serif;
  src: url(/static/reports/fonts/notoSerif-Italic.ba7b95d380e5540c19f7cce3bdb112fc.woff) format('woff'), url(/static/reports/fonts/notoSerif-Italic.7d1f3e08d07afda248a9e17498a9da5c.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-noto_serif,
.ql-container .ql-font-noto_serif * {
  font-family: "Noto Serif", serif;
}
@font-face {
  font-family: Open Sans;
  src: url(/static/reports/fonts/openSans-Regular.2b6f63fce9104d1223d83dd12cd6038e.woff) format('woff'), url(/static/reports/fonts/openSans-Regular.81d0487ba73afd292730e6f89e83c2ea.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Open Sans;
  src: url(/static/reports/fonts/openSans-Bold.efe9ead0aecdedc597ec9d4e745e0a58.woff) format('woff'), url(/static/reports/fonts/openSans-Bold.15df1fb3e82321d94a0ca758c62e25d2.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Open Sans;
  src: url(/static/reports/fonts/openSans-BoldItalic.a9c343d16f7be0984e4b9f97781d33e6.woff) format('woff'), url(/static/reports/fonts/openSans-BoldItalic.6f046d9736e0e0365ebbc9b25fa92e3b.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Open Sans;
  src: url(/static/reports/fonts/openSans-Italic.af3f8a1faecd92fed018201d8647399c.woff) format('woff'), url(/static/reports/fonts/openSans-Italic.7131a88d0a6fdeedd782ffe1b680b988.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-open_sans,
.ql-container .ql-font-open_sans * {
  font-family: "Open Sans", sans-serif;
}
@font-face {
  font-family: Overlock;
  src: url(/static/reports/fonts/overlock-Regular.adc3fb4a9215177f9e7f96bf9367077a.woff) format('woff'), url(/static/reports/fonts/overlock-Regular.f51dccc4e260f4bbe2faf94e76ac1432.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Overlock;
  src: url(/static/reports/fonts/overlock-Bold.044e9a49e5bd8913e1a88a8388f6e32a.woff) format('woff'), url(/static/reports/fonts/overlock-Bold.b3a6fb484c15018b6971b026151c991d.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Overlock;
  src: url(/static/reports/fonts/overlock-BoldItalic.fa445d5047829f1a94e068337e90a5c4.woff) format('woff'), url(/static/reports/fonts/overlock-BoldItalic.89f3da1d6700965e5b63ee590b7537c9.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Overlock;
  src: url(/static/reports/fonts/overlock-Italic.2d97f59e317b50dd72cad9d2ad353a75.woff) format('woff'), url(/static/reports/fonts/overlock-Italic.0bbfda398dfd4ec1a15164881fd344bf.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-overlock,
.ql-container .ql-font-overlock * {
  font-family: "Overlock", sans-serif;
}
@font-face {
  font-family: Playfair Display;
  src: url(/static/reports/fonts/playfairDisplay-Regular.744721894cc464730a9610e9a6e0075e.woff) format('woff'), url(/static/reports/fonts/playfairDisplay-Regular.79b12e38f5958e352c1dc6d5031a5e00.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Playfair Display;
  src: url(/static/reports/fonts/playfairDisplay-Bold.ef6258ee945f6cae26a89326c61b1530.woff) format('woff'), url(/static/reports/fonts/playfairDisplay-Bold.27c64a5e4305d0818457d62ae1cea006.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Playfair Display;
  src: url(/static/reports/fonts/playfairDisplay-BoldItalic.41336ab53081a21e2f2e4e1445338ddb.woff) format('woff'), url(/static/reports/fonts/playfairDisplay-BoldItalic.17bfe46c58f554ec3f825931ff27df5c.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Playfair Display;
  src: url(/static/reports/fonts/playfairDisplay-Italic.0972d6dc5fdd5937ad569e87eb3cb7e3.woff) format('woff'), url(/static/reports/fonts/playfairDisplay-Italic.8ba3da74f960a95d3c78667bc9bb575d.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-playfair_display,
.ql-container .ql-font-playfair_display * {
  font-family: "Playfair Display", serif;
}
@font-face {
  font-family: Playfair Display SC;
  src: url(/static/reports/fonts/playfairDisplaySc-Regular.be35a7a62d530341626e085ba1009d01.woff) format('woff'), url(/static/reports/fonts/playfairDisplaySc-Regular.8dadec36174905e67021b52e7828498f.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Playfair Display SC;
  src: url(/static/reports/fonts/playfairDisplaySc-Bold.210d45b6b6dbd37c3eea4b94b8ceef43.woff) format('woff'), url(/static/reports/fonts/playfairDisplaySc-Bold.b6215cf8bb1cafc1c932f6fe55e46a89.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Playfair Display SC;
  src: url(/static/reports/fonts/playfairDisplaySc-BoldItalic.49136edd153d797c25c7cb0545b21683.woff) format('woff'), url(/static/reports/fonts/playfairDisplaySc-BoldItalic.8573a9fbc85fdcfcce440d2d5008b6d2.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Playfair Display SC;
  src: url(/static/reports/fonts/playfairDisplaySc-Italic.6383c5817105eb47167fa44f12f103eb.woff) format('woff'), url(/static/reports/fonts/playfairDisplaySc-Italic.e5b44c035f955878c875ce6351288894.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-playfair_display_sc,
.ql-container .ql-font-playfair_display_sc * {
  font-family: "Playfair Display SC", serif;
}
@font-face {
  font-family: Prompt;
  src: url(/static/reports/fonts/prompt-Regular.072241c065c7cd01f7429a6d35717c7b.woff) format('woff'), url(/static/reports/fonts/prompt-Regular.5916025f285e07daf21e4ae4265d801a.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Prompt;
  src: url(/static/reports/fonts/prompt-Bold.10a46f15cbc265fc4886f5a51b6b8a8e.woff) format('woff'), url(/static/reports/fonts/prompt-Bold.f21b522616b56a0beffbbab487a8a905.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Prompt;
  src: url(/static/reports/fonts/prompt-BoldItalic.fdedba5dfef3548c756c8a5ec87ffc5b.woff) format('woff'), url(/static/reports/fonts/prompt-BoldItalic.85675e5547f3ca9f0a045f1a949a02e9.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Prompt;
  src: url(/static/reports/fonts/prompt-Italic.b2cec6e7ecb0f3ef4099dd9869cef007.woff) format('woff'), url(/static/reports/fonts/prompt-Italic.912b2c754007b8d6b7fb5320f5ac3ffd.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-prompt,
.ql-container .ql-font-prompt * {
  font-family: "Prompt", sans-serif;
}
@font-face {
  font-family: PT Serif;
  src: url(/static/reports/fonts/ptSerif-Regular.1fe237b6b4918529e5dfb73ea012fb5f.woff) format('woff'), url(/static/reports/fonts/ptSerif-Regular.5222d3ca6f5b4d104b386789e30ef3dd.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: PT Serif;
  src: url(/static/reports/fonts/ptSerif-Bold.898746cff14d934e5825819bb45606e3.woff) format('woff'), url(/static/reports/fonts/ptSerif-Bold.3a74c31cc0753849ba8f8f34f8513a57.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: PT Serif;
  src: url(/static/reports/fonts/ptSerif-BoldItalic.0527ba1750ebd5bffe991d50b7800986.woff) format('woff'), url(/static/reports/fonts/ptSerif-BoldItalic.6a79723b0ffb07a87ba2672422b5f95f.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: PT Serif;
  src: url(/static/reports/fonts/ptSerif-Italic.8be295050a7e2eb96dc5f26b78831814.woff) format('woff'), url(/static/reports/fonts/ptSerif-Italic.96294ae19544ce13ccf5236fa7075b6b.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-pt_serif,
.ql-container .ql-font-pt_serif * {
  font-family: "PT Serif", serif;
}
@font-face {
  font-family: Raleway;
  src: url(/static/reports/fonts/raleway-Regular.dcee86a85f73cc5dffdbb4654270c0bd.woff) format('woff'), url(/static/reports/fonts/raleway-Regular.c1bd33e805a88f721aff01ca0b5d6acb.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url(/static/reports/fonts/raleway-Bold.f16ff22bb18b1eb34c4f93c101c4ac78.woff) format('woff'), url(/static/reports/fonts/raleway-Bold.80cd2f56e83e6469ba53039c0780850f.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Raleway;
  src: url(/static/reports/fonts/raleway-BoldItalic.6429dc790077f681afa93efa0d7e83a8.woff) format('woff'), url(/static/reports/fonts/raleway-BoldItalic.03d545cd98321ab998dba741113a13a2.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Raleway;
  src: url(/static/reports/fonts/raleway-Italic.65e9765ef741eff4bbccd13f2b382d1a.woff) format('woff'), url(/static/reports/fonts/raleway-Italic.c1d572336c9eacc025a65c4177e0261a.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-raleway,
.ql-container .ql-font-raleway * {
  font-family: "Raleway", sans-serif;
}
@font-face {
  font-family: Roboto;
  src: url(/static/reports/fonts/roboto-Regular.1fb51f52c5d3adf198aa44e80478426e.woff) format('woff'), url(/static/reports/fonts/roboto-Regular.e5d046dd2c38f8e9a1f2f1130e4ec9fc.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url(/static/reports/fonts/roboto-Bold.d14ff5136e64dbb064089c93c8925904.woff) format('woff'), url(/static/reports/fonts/roboto-Bold.53eedb1e2d7c79582f367ec102c12681.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Roboto;
  src: url(/static/reports/fonts/roboto-BoldItalic.f67007ca63d1541714e35b0d2f0b4947.woff) format('woff'), url(/static/reports/fonts/roboto-BoldItalic.6beaf49138589913909d09701aac6181.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Roboto;
  src: url(/static/reports/fonts/roboto-Italic.882f0af85f688d84afb3ced39c3e9d28.woff) format('woff'), url(/static/reports/fonts/roboto-Italic.811e18d29e68c1e6e941f5252d8267a1.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-roboto,
.ql-container .ql-font-roboto * {
  font-family: "Roboto", sans-serif;
}
@font-face {
  font-family: Sansita;
  src: url(/static/reports/fonts/sansita-Regular.063f0a90d5aaccbef3d7080c7b6fe090.woff) format('woff'), url(/static/reports/fonts/sansita-Regular.bd40e8374ebdac4d01e47610b06b5d37.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Sansita;
  src: url(/static/reports/fonts/sansita-Bold.9b72941ef2c9f0c20ad903afa04c03d3.woff) format('woff'), url(/static/reports/fonts/sansita-Bold.300e54621992c0b0a2cc85f561d4d84e.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Sansita;
  src: url(/static/reports/fonts/sansita-BoldItalic.45b5ecd32e47f8d4035f5deb3cfb0547.woff) format('woff'), url(/static/reports/fonts/sansita-BoldItalic.72bf6ec79a7d180273d750f27d266559.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Sansita;
  src: url(/static/reports/fonts/sansita-Italic.7d4048a98a5122567a2efffb2472495a.woff) format('woff'), url(/static/reports/fonts/sansita-Italic.0635265eaee25f94a029456b5d5f4cc0.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-sansita,
.ql-container .ql-font-sansita * {
  font-family: "Sansita", sans-serif;
}
@font-face {
  font-family: Space Mono;
  src: url(/static/reports/fonts/spaceMono-Regular.5b36085bf39da01ebe78ea32ca4ecc02.woff) format('woff'), url(/static/reports/fonts/spaceMono-Regular.667096e8fef137f5d42cd2dd2f954c75.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Space Mono;
  src: url(/static/reports/fonts/spaceMono-Bold.91da39b27de8f3f8887942cfefe9e8a6.woff) format('woff'), url(/static/reports/fonts/spaceMono-Bold.71a870b8fc9c10c36f5ca77d718fd2a7.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Space Mono;
  src: url(/static/reports/fonts/spaceMono-BoldItalic.f84a8342e1a9d58634c2d43535c42ed9.woff) format('woff'), url(/static/reports/fonts/spaceMono-BoldItalic.f0b73da7bccc9b5906f93b3b939e3480.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Space Mono;
  src: url(/static/reports/fonts/spaceMono-Italic.73507d42ef7222b88409a6e0d1da2cec.woff) format('woff'), url(/static/reports/fonts/spaceMono-Italic.3fe9acd229ccd125cae501edc9ea61ec.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-space_mono,
.ql-container .ql-font-space_mono * {
  font-family: "Space Mono", monospace, sans-serif;
}
@font-face {
  font-family: Taviraj;
  src: url(/static/reports/fonts/taviraj-Regular.427e40045a0d4b70499d90c1825cdd58.woff) format('woff'), url(/static/reports/fonts/taviraj-Regular.58089898eef987a22fccbb4ce4a5d477.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Taviraj;
  src: url(/static/reports/fonts/taviraj-Bold.4492d017c63b426c5e227dc95f101590.woff) format('woff'), url(/static/reports/fonts/taviraj-Bold.e97f5903aab2db435aa1a12aa8301f55.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Taviraj;
  src: url(/static/reports/fonts/taviraj-BoldItalic.a29d74f016a3d49723b967e9966daa42.woff) format('woff'), url(/static/reports/fonts/taviraj-BoldItalic.0f14f6645e6937cc490e2b399c1d2d33.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Taviraj;
  src: url(/static/reports/fonts/taviraj-Italic.81b701456f8d4da7094721bf7097bd97.woff) format('woff'), url(/static/reports/fonts/taviraj-Italic.da6d1a9947d520dc7747b0f2a3b1fe90.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-taviraj,
.ql-container .ql-font-taviraj * {
  font-family: "Taviraj", serif;
}
@font-face {
  font-family: Ubuntu Mono;
  src: url(/static/reports/fonts/ubuntuMono-Regular.a1de47843f107a3fdf452597f1faf407.woff) format('woff'), url(/static/reports/fonts/ubuntuMono-Regular.0911f78ebeaf39a35e9a401fe312eccb.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Ubuntu Mono;
  src: url(/static/reports/fonts/ubuntuMono-Bold.331f88057deafdc21466d8d4541a6732.woff) format('woff'), url(/static/reports/fonts/ubuntuMono-Bold.152b5a4bc2e1d3bb5e051ab26d6dad48.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Ubuntu Mono;
  src: url(/static/reports/fonts/ubuntuMono-BoldItalic.157f7450ae560e4b62d1d87d72667029.woff) format('woff'), url(/static/reports/fonts/ubuntuMono-BoldItalic.e675d38edba6f3bf9e570b5905170117.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Ubuntu Mono;
  src: url(/static/reports/fonts/ubuntuMono-Italic.2602654a38b7c13ee28fc8fdb62aa1bc.woff) format('woff'), url(/static/reports/fonts/ubuntuMono-Italic.33324a4b71ea2975b97499138d937341.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-ubuntu_mono,
.ql-container .ql-font-ubuntu_mono * {
  font-family: "Ubuntu Mono", monospace, sans-serif;
}
@font-face {
  font-family: Unna;
  src: url(/static/reports/fonts/unna-Regular.cc723cde0d01dba1464e57c9edd40a62.woff) format('woff'), url(/static/reports/fonts/unna-Regular.3512107c2cfc2e8bc6d3dfc4cebf3918.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Unna;
  src: url(/static/reports/fonts/unna-Bold.598a57ccaf21f48c25a1773b7b60d337.woff) format('woff'), url(/static/reports/fonts/unna-Bold.281c35f2c924f91966bb28f6ceb56a2e.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Unna;
  src: url(/static/reports/fonts/unna-BoldItalic.2efc7d614771d49db6d028c4cbb501c8.woff) format('woff'), url(/static/reports/fonts/unna-BoldItalic.9be7daa9ed05929de56bce52937ef3bd.woff2) format('woff2');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: Unna;
  src: url(/static/reports/fonts/unna-Italic.7f3e1e7418f66db23d10d7e4099c60b9.woff) format('woff'), url(/static/reports/fonts/unna-Italic.d194e89b377151144cbaace20a68bcec.woff2) format('woff2');
  font-weight: 400;
  font-style: italic;
}
.ql-container .ql-font-unna,
.ql-container .ql-font-unna * {
  font-family: "Unna", serif;
}
