pr-page-margin-positioner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;
}
pr-page-margin-positioner pr-page-header-footer {
  pointer-events: auto;
}
.pr-page-margin-positioner {
  position: absolute;
  z-index: 0;
}
